export const INLINE_FILTERS_KEYS = Object.freeze({
  dominate: {
    hotInventory: 'dominate-hot-inventory',
    interviews: 'dominate-interviews',
    outstandingBillings: 'dominate-outstanding-billings',
    followUpTasks: 'dominate-follow-up-tasks'
  },
  activity: {
    overallPerformance: 'activity-overall-performance',
    overallPerformanceByTeam: 'activity-overall-performance-by-team'
  }
});

export const TABLES_KEYS = Object.freeze({
  drawerPlacements: 'drawer-placements',
  drawerSendouts: 'drawer-sendouts',
  drawerSendovers: 'drawer-sendovers',
  drawerSentAgreements: 'drawer-sent-agreements',
  drawerSignedAgreements: 'drawer-signed-agreements',
  followUpTasksUnconvertedSendovers: 'dominate-follow-up-tasks-unconverted-sendovers',
  followUpTasksUnsignedAgreements: 'dominate-follow-up-tasks-unsigned-agreements',
  healthDistributionStats: 'health-distribution-stats'
});
