import React from 'react';
import strings from 'strings';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import LoaderContent from 'UI/components/templates/DrawerContentLayout/LoaderContent';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { UIStatus } from 'UI/constants/status';
import { SvgNoJobOrders } from 'UI/res';

import { Chat, MessageComposer } from './components';
import { useChat } from './useChat';

const COPIES = strings.messageCenter;

export const DrawerChat = ({ onClose, open, recipient }) => {
  const title = recipient?.personalInformation?.full_name;
  const subheader = 'Phone Number'; // TODO: Add Phone Number

  const { chat, handleClickSend, chatStatus } = useChat();

  return (
    <DrawerContentLayout
      title={title}
      subheader={subheader}
      CustomFooter={
        <MessageComposer disabled={chatStatus !== UIStatus.Success} onClickSend={handleClickSend} />
      }
      drawerProps={{
        open
      }}
      onClose={onClose}
    >
      {chatStatus === UIStatus.Loading && <LoaderContent />}
      {chatStatus === UIStatus.Success && <Chat chat={chat} />}
      {chatStatus === UIStatus.Error && (
        <EmptyPlaceholder
          title={COPIES.emptyState.errorTitle}
          subtitle={COPIES.emptyState.errorSubtitle}
          customEmptyState={<SvgNoJobOrders height={400} />}
        />
      )}
    </DrawerContentLayout>
  );
};
