/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { VersioningStrategy, withVersioning } from 'axios-api-versioning';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { cleanLocalStorage, getRefreshToken, getToken } from 'services/Authentication';
import { EnvApiUrls } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';

let isRefreshing = false;
let failedQueue = [];

export const getCancelToken = () => axios.CancelToken.source();

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const redirectTime = () => {
  setTimeout(() => {
    window.location.pathname = '/login';
  }, 1000);
};

const configureAxiosInstance = config => {
  const instance = withVersioning(axios.create(config), {
    apiVersion: '1',
    versioningStrategy: VersioningStrategy.UrlPath
  });

  instance.defaults.headers.Timezone = -new Date().getTimezoneOffset() / 60;

  // Request interceptor
  instance.interceptors.request.use(
    request => {
      const token = getToken();
      if (token) request.headers.Authorization = `Bearer ${token}`;
      return request;
    },
    error => Promise.reject(error)
  );

  // Response interceptor
  instance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;

      if (!error?.response?.status) {
        return Promise.reject(error);
      }

      if (error.response.status === HTTPStatusCodes.Forbidden && error.response.data.isInactive) {
        cleanLocalStorage();
        if (error.response.data.redirect) {
          redirectTime();
        }
      }

      const isErrorStatus = [
        HTTPStatusCodes.BadRequest,
        HTTPStatusCodes.NotFound,
        HTTPStatusCodes.InternalServerError
      ].includes(error.response.status);

      if (isErrorStatus && originalRequest.url === Endpoints.UsersRefreshToken) {
        cleanLocalStorage();
        redirectTime();
      }

      if (error.response.status === HTTPStatusCodes.Unauthorized && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return instance(originalRequest);
            })
            .catch(err => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = getRefreshToken();

        return new Promise((resolve, reject) => {
          instance
            .post(Endpoints.UsersRefreshToken, { refresh_token: refreshToken })
            .then(response => {
              if ([HTTPStatusCodes.Ok, HTTPStatusCodes.Created].includes(response.status)) {
                const accessResponse = response?.data?.token;
                localStorage.setItem('access', JSON.stringify(accessResponse));
                const tokenResponse = accessResponse?.token ?? '';
                processQueue(null, tokenResponse);
                resolve(instance(originalRequest));
              }
            })
            .catch(err => {
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const MAIN_API_CLIENT = configureAxiosInstance({
  baseURL: EnvApiUrls.MAIN_VERSIONED,
  responseType: 'json'
});

export const JSON_SCHEMA_CLIENT = configureAxiosInstance({
  baseURL: EnvApiUrls.API_URL_AWS,
  headers: {
    'x-api-key': EnvApiUrls.API_KEY_AWS
  },
  responseType: 'json'
});

export const SECONDARY_API_CLIENT = JSON_SCHEMA_CLIENT;

export default MAIN_API_CLIENT;
