// @flow
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { confirm as confirmAction, showAlert } from 'actions/app';
import { hideReferenceReleaseComposer as hideReferenceReleaseComposerAction } from 'actions/joborder';
import { useDrawer } from 'hooks/drawerHandler';
import { useProfile } from 'hooks/profileActions';
import { useVerifyUrgencyDialogManager } from 'hooks/useVerifyUrgencyDialogManager';
import { UserProviderContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import { canUserOrStaffEditEntity, userHasRoles } from 'services/Authorization';
import { JobOrderIncludes } from 'services/includes';
import { getSendoutById } from 'services/Sendouts';
import strings from 'strings';
import NotAvailable from 'UI/components/molecules/NotAvailable';
import { VerifyUrgencyDialog } from 'UI/components/molecules/VerifyUrgencyDialog';
import ReferenceRelease from 'UI/components/organisms/candidates/ReferenceRelease';
import {
  handleEntityEditionCompleted,
  handleWriteUpActions
} from 'UI/components/organisms/inventoryProfiles/utils';
import JobOrderEdit from 'UI/components/organisms/JobOrderEdit';
import JobOrderSheet from 'UI/components/organisms/JobOrderSheet';
import { PlacementCreateManager } from 'UI/components/organisms/placements/PlacementCreateManager';
import { getPlacementActions } from 'UI/components/organisms/placements/utils';
import ReassignRecruiterForm from 'UI/components/organisms/ReassignRecruiter';
import SendoutsForm from 'UI/components/organisms/sendouts/SendoutForm';
import InventoryProfileLayout from 'UI/components/templates/InventoryProfileLayout';
import ProfileUiStateManager from 'UI/components/templates/ProfileUiStateManager';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { CompanyType, EntityType, JobOrderEntity, TabKeys } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { EmptyFeeAgreement } from 'UI/res/images';
import { getErrorMessage, localTimeFormatter } from 'UI/utils';
import { completeReferenceRelease, sendoutDataToForm } from 'UI/utils/inventory';
import { updateUiState } from 'UI/utils/uiHelpers';

import { useJobOrderProfileFeatureFlags } from './JobOrderProfile.hooks';

type JobOrderProfileProps = {
  enableUrlNavigation: boolean,
  id: number,
  layoutClassName: Object,
  match: any,
  onEditionCompleted: Function,
  onVerificationCompleted: () => void
};

const {
  inventoryProfiles: {
    sections: {
      jobOrders: { notAvailableStrings, duplicateDialog: duplicateDialogCopies }
    }
  },
  inventory: {
    jobOrders: {
      profile: { editButton }
    }
  }
} = strings;

const createTabsProps = ({
  endpoint,
  onActivityComplete,
  onNewPlacementClick,
  onSendoutClick,
  onTriggerTabRefresh,
  onWriteUpClick,
  profileModule,
  profileName,
  results,
  shouldRefresh,
  tabKeys,
  uiState
}) => [
  {
    id: TabKeys.Overview,
    tabProps: {
      profile: results,
      onWriteUpClick
    }
  },
  {
    id: TabKeys.Sendouts,
    tabProps: {
      profile: results,
      profileModule: EntityType.Joborder,
      onNewItemClick: onSendoutClick,
      onSendoutClick,
      shouldRefresh
    }
  },
  {
    id: TabKeys.Placements,
    tabProps: {
      key: tabKeys.placements,
      onNewPlacementClick,
      onTriggerTabRefresh,
      profileId: results?.id,
      profileModule: EntityType.Joborder
    }
  },
  {
    id: TabKeys.Candidates,
    tabProps: {
      uiState,
      joborderId: results?.id,
      endpoint,
      profileName
    }
  },
  {
    id: TabKeys.HiringAuthorities,
    tabProps: {
      profile: results,
      search: false
    }
  },
  {
    id: TabKeys.ActivityLogs,
    tabProps: {
      itemName: results?.title,
      onActivityComplete,
      profileId: results?.id,
      profileModule,
      endpoint
    }
  },
  {
    id: TabKeys.Notes,
    tabProps: {
      itemName: results?.title,
      onActivityComplete,
      profileId: results?.id,
      profileModule: EntityType.Joborder,
      endpoint
    }
  }
];

const FPJobOrderProfile = ({
  enableUrlNavigation,
  id,
  layoutClassName,
  match,
  onEditionCompleted,
  onVerificationCompleted
}: JobOrderProfileProps) => {
  const location = useLocation();

  const joborderId = id ?? match.params.id;

  const endpoint = `${Endpoints.JobOrders}/${joborderId}`;
  const history = useHistory();
  const dispatch = useDispatch();
  const { referenceRelease } = useSelector(state => state.jobOrder);
  const [currentUser] = useContext(UserProviderContext);
  const editionDrawer = useDrawer(location, history);
  const {
    isOpen: isOpenVerifyUrgencyDialog,
    openDialog: openVerifyUrgencyDialog,
    closeDialog: closeVerifyUrgencyDialog
  } = useVerifyUrgencyDialogManager({
    entity: JobOrderEntity.title
  });
  const { canUseChangeLogsTabs, canUseUrgencyVerification } = useJobOrderProfileFeatureFlags();

  /** To keep track of tabs that should refresh after an event.
   * Remember that a component unmount when key changes */
  const [tabKeys, setTabKeys] = useState({
    sendouts: 0,
    placements: 1
  });

  const {
    handleEditClick,
    handleEditClosed,
    handleEditCompleted,
    handleProfileStateUpdate,
    handleReassignClick,
    handleReassignClosed,
    handleReassignCompleted,
    isLoading,
    refreshData: refreshProfile,
    results,
    setUiState,
    state,
    status,
    uiState
  } = useProfile({
    entity: EntityType.Joborder,
    profileId: joborderId,
    includes: JobOrderIncludes,
    shouldLoadTitle: enableUrlNavigation
  });

  const itemName = results?.title;
  const lastActivity = results?.last_activity_date;

  const handleRefreshActivityStatus = data =>
    handleProfileStateUpdate({ last_activity_date: data?.updated_at });
  const formattedDate = localTimeFormatter(lastActivity, DateFormats.SimpleDateTime);

  const triggerTabRefresh = (nameTab: string) =>
    setTabKeys(prevState => ({ ...prevState, [nameTab]: new Date().getTime() }));

  const handleRefreshTable = () => updateUiState({ shouldRefreshTableData: true }, setUiState);

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   P L A C E M E N T   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const referenceReleaseHandler = () => dispatch(hideReferenceReleaseComposerAction());
  const { handleReferenceReleaseComplete } = completeReferenceRelease({
    referenceRelease,
    referenceReleaseHandler
  });

  const handleNewPlacementClick = useCallback(
    ({ sendoutId }) => {
      updateUiState(
        {
          isPlacementOpen: true,
          selectedPlacementId: null,
          selectedSendoutId: sendoutId ? parseInt(sendoutId, 10) : null
        },
        setUiState
      );
    },
    [setUiState]
  );

  const { handlePlacementClosed, handlePlacementCompleted } = getPlacementActions({
    history,
    location,
    onRefreshPlacementTable: handleRefreshTable,
    onTriggerTabRefresh: triggerTabRefresh,
    setUiState
  });

  const handleNewSendoutClick = () => {
    handlePlacementClosed();
    handleSendoutClick();
  };

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   D U P L I C A T E   J O B  O R D E R
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleDuplicateClick = async () => {
    dispatch(
      confirmAction({
        severity: 'warning',
        ...duplicateDialogCopies,
        cancelButtonText: strings.shared.ui.cancel,
        onConfirm: async ok => {
          try {
            if (!ok) return;

            setUiState(prevState => ({ ...prevState, isLoading: true }));
            const response = await API.post(`${endpoint}/duplicate`);
            if (response.data && response.status === 201) {
              dispatch(
                showAlert({
                  severity: 'success',
                  title: strings.inventoryProfiles.sections.jobOrders.successDuplication
                })
              );

              const { id: newJobOrderId } = response.data.data;
              history.push(EntityRoutes.JobOrderProfile.replace(':id', newJobOrderId));
            }
          } catch (error) {
            dispatch(
              showAlert({
                severity: 'error',
                title: 'Job Order',
                autoHideDuration: 5000,
                body: getErrorMessage(error)
              })
            );
          } finally {
            setUiState(prevState => ({ ...prevState, isLoading: false }));
          }
        }
      })
    );
  };
  const userCanEdit = canUserOrStaffEditEntity(currentUser, results);

  const extraMenuItems = [
    {
      title: 'Duplicate',
      action: handleDuplicateClick,
      visible: userCanEdit
    }
  ];

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   S E N D O U T S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleSendoutClick = async (sendout: any) => {
    if (sendout && sendout.id) {
      const sendoutResponse = await getSendoutById(sendout.id);
      if (!sendoutResponse.data) return;
      const formattedSendout = sendoutDataToForm(sendoutResponse.data, {
        id: sendout?.id,
        title: sendout?.title
      });
      updateUiState(
        {
          isSendoutOpen: true,
          selectedSendout: formattedSendout
        },
        setUiState
      );
      return;
    }
    updateUiState(
      {
        isSendoutOpen: true,
        selectedSendoutId: null,
        selectedSendout: null
      },
      setUiState
    );
  };

  const isSendoutAvailable =
    results && results?.company?.type?.id !== CompanyType.NotSigned && uiState.isSendoutOpen;

  const goToCompanyProfile = () =>
    history.push(EntityRoutes.CompanyProfile.replace(':id', results?.company?.id));

  const handleSendoutCompleted = async () => {
    triggerTabRefresh('sendouts');
    handleRefreshTable();
    handleSendoutClosed();
    await refreshProfile();
  };

  const handleSendoutNavigation = (event, options) => {
    handleSendoutClosed();
    const { sendoutId, placementId } = options || {};
    placementId && handleNewPlacementClick({ id: placementId });
    sendoutId && handleNewPlacementClick({ sendoutId });
  };

  const handleSendoutClosed = () =>
    updateUiState({ isSendoutOpen: false, shouldRefreshTableData: false }, setUiState);

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   W H I T E   S H E E T
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleDataSheetClick = () => {
    history.push(EntityRoutes.JobOrderEdit.replace(':id', results?.id));
  };

  const handleWhitesheetClosed = () =>
    uiState.isWriteUpOpen && updateUiState({ isWriteUpOpen: false }, setUiState);

  const handleWriteUpdate = () =>
    handleWriteUpActions({
      onWriteUpClose: handleWhitesheetClosed,
      onRefreshData: refreshProfile
    });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   T A B S   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const tabsProps = createTabsProps({
    results,
    onWriteUpClick: handleDataSheetClick,
    onSendoutClick: handleSendoutClick,
    shouldRefresh: uiState.shouldRefreshTableData,
    onTriggerTabRefresh: () => triggerTabRefresh(TabKeys.Placements),
    tabKeys,
    onNewPlacementClick: handleNewPlacementClick,
    uiState,
    endpoint,
    onActivityComplete: handleRefreshActivityStatus,
    profileModule: EntityType.Joborder,
    profileName: itemName
  });

  const canUserEdit = canUserOrStaffEditEntity(currentUser, results) || !results;

  const writeUpActions = (() => {
    return [
      {
        title: editButton,
        action: handleDataSheetClick,
        visible: canUserEdit
      },
      {
        title: 'Verify Urgency',
        action: openVerifyUrgencyDialog,
        visible:
          canUseUrgencyVerification &&
          userHasRoles([Roles.RegionalDirector, Roles.AssistantRegionalDirector, Roles.Leadership])
      }
    ];
  })();

  const headerProps = {
    sectionName: 'Job Order',
    title: itemName
  };

  const handleRefreshProfile = async () => {
    await refreshProfile();
  };

  return (
    <ProfileUiStateManager
      status={status}
      responseStatusCode={state.responseStatusCode}
      entityRoute={EntityRoutes.JobOrders}
      onRefreshProfileClick={handleRefreshProfile}
      entity={JobOrderEntity}
      profileStatusMode={{
        loading: (
          <InventoryProfileLayout
            entityType={JobOrderEntity}
            contentPageLayoutProps={{ className: layoutClassName }}
            isLoading={isLoading}
            isChangeLogsTabEnabled={canUseChangeLogsTabs}
            results={results}
            enableUrlNavigation={enableUrlNavigation}
            ProfileTabsProps={{
              endpoint,
              profileTabProps: tabsProps
            }}
          />
        ),
        success: (
          <>
            <InventoryProfileLayout
              activityLabel={formattedDate}
              addItemLabel="Add a Collaborator"
              contentPageLayoutProps={{ className: layoutClassName }}
              entityType={JobOrderEntity}
              headerCardProps={headerProps}
              inventoryType={results?.whiteSheet?.jobOrderType}
              isLoading={isLoading}
              isChangeLogsTabEnabled={canUseChangeLogsTabs}
              recruiters={results?.additionalRecruiters}
              results={results}
              enableUrlNavigation={enableUrlNavigation}
              profileMenuProps={{
                onReassignClick: handleReassignClick,
                onEditClick: handleEditClick,
                customMenuItems: extraMenuItems,
                writeUpActions
              }}
              ProfileTabsProps={{
                endpoint,
                profileTabProps: tabsProps
              }}
            />

            {!isLoading && results?.id && (
              <>
                <Drawer open={uiState.isEditOpen} onClose={handleEditClosed}>
                  <div role="presentation">
                    <JobOrderEdit
                      joborder={results}
                      onEditCompleted={handleEntityEditionCompleted({
                        handleEditCompleted,
                        enableUrlNavigation,
                        onEditionCompleted
                      })}
                      onEditClosed={handleEditClosed}
                    />
                  </div>
                </Drawer>

                <Drawer open={uiState.isWriteUpOpen} onClose={handleWhitesheetClosed}>
                  <div role="presentation">
                    {uiState.isWriteUpOpen && (
                      <JobOrderSheet
                        joborderId={joborderId}
                        companyId={results?.company?.id || 0}
                        whitesheet={results?.whiteSheet}
                        profile={results}
                        isReadOnly={uiState.isWriteUpReadOnly}
                        onWhitesheetCompleted={handleWriteUpdate}
                        onWhitesheetClosed={handleWhitesheetClosed}
                      />
                    )}
                  </div>
                </Drawer>

                <Drawer open={uiState.isSendoutOpen} onClose={handleSendoutClosed}>
                  {isSendoutAvailable && uiState.isSendoutOpen && (
                    <SendoutsForm
                      sendoutData={uiState.selectedSendout}
                      profile={results}
                      onCreate={handleSendoutCompleted}
                      onEdit={handleSendoutCompleted}
                      onNavigate={handleSendoutNavigation}
                      onDelete={handleSendoutCompleted}
                      onClose={handleSendoutClosed}
                      onDuplicateSendoutSelected={handleSendoutClick}
                    />
                  )}
                  {!isSendoutAvailable && uiState.isSendoutOpen && (
                    <NotAvailable
                      {...notAvailableStrings}
                      buttonAction={goToCompanyProfile}
                      ImageRender={EmptyFeeAgreement}
                      onClose={handleSendoutClosed}
                    />
                  )}
                </Drawer>
                {uiState.isPlacementOpen && !uiState.selectedPlacementId && (
                  <Drawer open={uiState.isPlacementOpen} onClose={handlePlacementClosed}>
                    <PlacementCreateManager
                      sendoutId={uiState.selectedSendoutId}
                      companyId={results?.company?.id}
                      jobOrderId={joborderId}
                      onClose={handlePlacementClosed}
                      onCompleted={handlePlacementCompleted}
                      onNewSendoutClick={handleNewSendoutClick}
                    />
                  </Drawer>
                )}
                {referenceRelease?.candidateId && (
                  <Drawer open onClose={referenceReleaseHandler}>
                    <ReferenceRelease
                      candidateId={referenceRelease.candidateId}
                      onClose={referenceReleaseHandler}
                      onCompleted={handleReferenceReleaseComplete}
                    />
                  </Drawer>
                )}
                <Drawer
                  open={uiState.isReassignOpen}
                  onClose={editionDrawer.toggleDrawer('isReassignOpen', false)}
                >
                  <div role="presentation">
                    <ReassignRecruiterForm
                      baseEndpoint={Endpoints.JobOrders}
                      item={results}
                      entityType={JobOrderEntity}
                      onReassignCompleted={handleReassignCompleted}
                      onReassignClosed={handleReassignClosed}
                    />
                  </div>
                </Drawer>
                {isOpenVerifyUrgencyDialog && (
                  <VerifyUrgencyDialog
                    entityId={joborderId}
                    entityType={EntityType.Joborder}
                    open={isOpenVerifyUrgencyDialog}
                    onClose={closeVerifyUrgencyDialog}
                    onSaveSucceeded={() => {
                      closeVerifyUrgencyDialog();
                      onVerificationCompleted && onVerificationCompleted();
                    }}
                  />
                )}
              </>
            )}
          </>
        )
      }}
    />
  );
};

export default FPJobOrderProfile;
