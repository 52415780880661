import React, { Fragment, useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import strings from 'strings';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { SMSEmptyState } from 'UI/res';

import { getDateLabel } from '../chat.utils';

import { Message } from './Message';
import { DateTitle } from './Message.styled';

const COPIES = strings.messageCenter;

const ChatMessages = ({ messages }) => {
  return (
    <>
      {messages.map(message => (
        <Message key={`${message.id}-${message.timestamp}`} {...message} />
      ))}
    </>
  );
};

export const Chat = ({ chat }) => {
  const chatEndRef = useRef(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chat]);

  if (isEmpty(chat)) {
    return (
      <EmptyPlaceholder
        title={COPIES.emptyState.title}
        subtitle={COPIES.emptyState.subtitle}
        customEmptyState={<SMSEmptyState height={400} />}
      />
    );
  }

  return (
    <>
      {Object.keys(chat).map(date => (
        <Fragment key={date}>
          <DateTitle variant="body2">{getDateLabel(date)}</DateTitle>

          <ChatMessages messages={chat[date]} />
        </Fragment>
      ))}

      <div ref={chatEndRef} />
    </>
  );
};
