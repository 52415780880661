import {
  FallOffsConfig,
  HealthDistributionAtRiskConfig,
  HealthDistributionBehindConfig,
  HealthDistributionHeadCountConfig,
  HealthDistributionInTrainingConfig,
  HealthDistributionOnTrackConfig,
  HealthDistributionUnder3MonthsConfig,
  PlacementsConfig,
  SendoutsConfig,
  SendoversConfig,
  SentAgreementsConfig,
  SignedAgreementsConfig
} from './components';

export const DRAWER_KEYS = Object.freeze({
  FallOffs: 'FallOffs',
  HealthDistributionHeadcount: 'HealthDistributionHeadcount',
  HealthDistributionInTraining: 'HealthDistributionInTraining',
  HealthDistributionAtRisk: 'HealthDistributionAtRisk',
  HealthDistributionBehind: 'HealthDistributionBehind',
  HealthDistributionUnder3Months: 'HealthDistributionUnder3Months',
  HealthDistributionOnTrack: 'HealthDistributionOnTrack',
  Placements: 'Placements',
  Sendouts: 'Sendouts',
  Sendovers: 'Sendovers',
  SentAgreements: 'SentAgrements',
  SignedAgreements: 'SignedAgreements'
});

export const DRAWERS_CONFIG = {
  [DRAWER_KEYS.HealthDistributionHeadcount]: HealthDistributionHeadCountConfig,
  [DRAWER_KEYS.HealthDistributionInTraining]: HealthDistributionInTrainingConfig,
  [DRAWER_KEYS.HealthDistributionAtRisk]: HealthDistributionAtRiskConfig,
  [DRAWER_KEYS.HealthDistributionBehind]: HealthDistributionBehindConfig,
  [DRAWER_KEYS.HealthDistributionUnder3Months]: HealthDistributionUnder3MonthsConfig,
  [DRAWER_KEYS.HealthDistributionOnTrack]: HealthDistributionOnTrackConfig,
  [DRAWER_KEYS.Placements]: PlacementsConfig,
  [DRAWER_KEYS.FallOffs]: FallOffsConfig,
  [DRAWER_KEYS.Sendouts]: SendoutsConfig,
  [DRAWER_KEYS.Sendovers]: SendoversConfig,
  [DRAWER_KEYS.SentAgreements]: SentAgreementsConfig,
  [DRAWER_KEYS.SignedAgreements]: SignedAgreementsConfig
};
