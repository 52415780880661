import { useEffect, useState } from 'react';
import moment from 'moment';
import { UIStatus } from 'UI/constants/status';

import { MESSAGE_DIRECTION, MESSAGE_STATUS } from './chat.constants';
import {
  addMessageToChat,
  fetchChat,
  groupMessagesByDate,
  sendMessageToServer,
  updateMessageStatus
} from './chat.utils';

export const useChat = () => {
  const [chat, setChat] = useState({});
  const [chatStatus, setChatStatus] = useState(UIStatus.Default);

  useEffect(() => {
    setChatStatus(UIStatus.Loading);

    fetchChat()
      .then(response => {
        setChat(groupMessagesByDate(response));
        setChatStatus(UIStatus.Success);
      })
      .catch(() => {
        setChatStatus(UIStatus.Error);
      });
  }, []);

  const handleClickSend = async message => {
    const timestamp = moment().local().format();
    const date = timestamp.split('T')[0];
    const newId = chat[date] ? chat[date].length + 1 : 1;

    const newMessage = {
      id: newId,
      sender: {
        id: 101,
        name: 'Alice'
      },
      message,
      timestamp,
      date,
      status: MESSAGE_STATUS.sending,
      direction: MESSAGE_DIRECTION.outbound
    };
    setChat(prevChat => addMessageToChat(prevChat, newMessage));

    const updateStatus = async status => {
      setChat(prevChat =>
        updateMessageStatus({
          chat: prevChat,
          messageToUpdate: newMessage,
          status
        })
      );
    };

    try {
      await sendMessageToServer(newMessage);
      await updateStatus(MESSAGE_STATUS.success);
    } catch (error) {
      await updateStatus(MESSAGE_STATUS.error);
    }
  };

  return {
    chat,
    handleClickSend,
    chatStatus
  };
};

export const useUiChatState = () => {
  const [uiChatState, setUiChatState] = useState({
    openDrawer: true // TODO: change to false. Also in useChat.test.jsx
  });

  const toggleDrawerChat = () => {
    setUiChatState(prevState => ({ ...prevState, openDrawer: !prevState.openDrawer }));
  };

  return {
    uiChatState,
    toggleDrawerChat
  };
};
