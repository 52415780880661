export const MESSAGE_DIRECTION = Object.freeze({
  outbound: 'outbound',
  inbound: 'inbound'
});

export const MESSAGE_STATUS = Object.freeze({
  delivered: 'delivered',
  received: 'received',
  sending: 'sending',
  error: 'error',
  success: 'success'
});
