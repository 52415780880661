// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import { confirm, showAlert } from 'actions/app';
import { useProfile } from 'hooks/profileActions';
import { useSearchProjectInProfiles } from 'hooks/searchProject';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import { HiringAuthorityIncludes } from 'services/includes';
import strings from 'strings';
import LinkCandidate from 'UI/components/organisms/HiringAuthorities/Candidate/LinkCandidate';
import HiringAuthorityDrawer from 'UI/components/organisms/HiringAuthorityDrawer';
import { getSendoutActions } from 'UI/components/organisms/inventoryProfiles/SendoutsTab/sendoutsActions';
import ToggleProfileModeButton from 'UI/components/organisms/inventoryProfiles/ToggleProfileModeButton';
import {
  getOptOutDate,
  handleEntityEditionCompleted
} from 'UI/components/organisms/inventoryProfiles/utils';
import SendoutManager from 'UI/components/organisms/sendouts';
import InventoryProfileLayout from 'UI/components/templates/InventoryProfileLayout';
import ProfileUiStateManager from 'UI/components/templates/ProfileUiStateManager';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import {
  EntityType,
  HiringAuthorityEntity,
  SearchProjectEntities,
  TabKeys
} from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getErrorMessage, getId, hasFeatureFlag, localTimeFormatter } from 'UI/utils';

type JobOrderProfileProps = {
  enableUrlNavigation: boolean,
  id: number,
  layoutClassName: Object,
  match: any,
  profileModeProps: Object
};

const hiringAuthorityApiVersion = 2;

const createTabsProps = ({
  endpoint,
  hiringAuthorityName,
  isLoading,
  itemName,
  onActivityComplete,
  profileId,
  profileName,
  refreshData,
  results,
  searchProjectsProps,
  location,
  createSendoutsProps: { handleSendoutClick, shouldRefreshTableData }
}) => [
  {
    id: TabKeys.Companies,
    tabProps: {
      onCompaniesChanged: refreshData,
      profileId,
      endpoint,
      profileName,
      location
    }
  },
  {
    id: TabKeys.JobOrders,
    tabProps: {
      profileId: results?.id,
      isLoading,
      itemName,
      mode: EntityType.HiringAuthority
    }
  },
  searchProjectsProps,
  {
    id: TabKeys.Sendouts,
    tabProps: {
      profile: results,
      profileModule: EntityType.Candidate,
      onSendoutClick: handleSendoutClick,
      shouldRefresh: shouldRefreshTableData
    }
  },
  {
    id: TabKeys.ActivityLogs,
    tabProps: {
      itemName: hiringAuthorityName,
      apiVersion: hiringAuthorityApiVersion,
      profileId: results?.id,
      profileModule: EntityType.HiringAuthority,
      onActivityComplete,
      endpoint
    }
  },
  {
    id: TabKeys.Notes,
    tabProps: {
      itemName: hiringAuthorityName,
      apiVersion: hiringAuthorityApiVersion,
      profileId: results?.id,
      profileModule: EntityType.HiringAuthority,
      endpoint
    }
  }
];
const HiringAuthorityProfile = ({
  enableUrlNavigation,
  id,
  layoutClassName,
  match,
  onEditionCompleted,
  profileModeProps
}: JobOrderProfileProps) => {
  const history = useHistory();
  const location = useLocation();

  const hiringAuthorityId = id ?? match?.params?.id;
  const endpoint = `${Endpoints.HiringAuthorities}/${hiringAuthorityId}`;
  const isChangeLogsTabEnabled = hasFeatureFlag(FeatureFlags.ChangeLogHA);

  const dispatch = useDispatch();

  const [tabKeys, setTabKeys] = useState({
    [TabKeys.SearchProjects]: 0
  });

  const triggerTabRefresh = tabName => setTabKeys(prev => ({ ...prev, [tabName]: getId() }));

  const {
    handleEditClick,
    handleEditClosed,
    handleEditCompleted,
    handleProfileStateUpdate,
    isLoading,
    refreshData,
    results,
    setUiState,
    state,
    status,
    uiState
  } = useProfile({
    entity: EntityType.HiringAuthority,
    profileId: hiringAuthorityId,
    includes: HiringAuthorityIncludes,
    shouldLoadTitle: enableUrlNavigation
  });

  const { id: profileId } = results;

  const hiringAuthorityName = results?.full_name;
  const hiringAuthorityRelatedWithCandidate = !!results?.relation?.candidate_id;
  const formattedDate = localTimeFormatter(
    results?.lastActivityLog?.created_at,
    DateFormats.SimpleDateTime
  );

  const isProfileLoaded = !isLoading && profileId;

  const handleRefreshActivityStatus = data =>
    handleProfileStateUpdate({ lastActivityLog: data?.updated_at });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   S E N D O U T S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const {
    handleSendoutClick,
    handleSendoutNavigation,
    handleSendoutCompleted,
    handleSendoutClosed
  } = getSendoutActions({ setUiState, history, triggerTabRefresh });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   S E A R C H   P R O J E C T S   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const { SearchProjectForms, SearchProjectsMenuItems, searchProjectTabPropsDefinition } =
    useSearchProjectInProfiles({
      endpoint,
      entity: SearchProjectEntities.HiringAuthority,
      profile: {
        id: profileId,
        name: hiringAuthorityName
      },
      tabKey: tabKeys.searchProjects,
      triggerTabRefresh,
      shouldRedirectToSearchProjectPreview: enableUrlNavigation
    });

  const tabsProps = createTabsProps({
    results,
    hiringAuthorityName,
    profileId,
    refreshData,
    endpoint,
    location,
    isLoading,
    itemName: hiringAuthorityName,
    onActivityComplete: handleRefreshActivityStatus,
    profileName: hiringAuthorityName,
    createSendoutsProps: {
      shouldRefreshTableData: uiState.shouldRefreshTableData,
      handleSendoutClick
    },
    searchProjectsProps: searchProjectTabPropsDefinition
  });

  const headerProps = {
    sectionName: 'Hiring Authority',
    title: hiringAuthorityName
  };

  const handleLinkCandidateClick = type =>
    setUiState(prevState => ({ ...prevState, [type]: true }));

  const handleCreateCandidateClick = () => {
    history.push(`${EntityRoutes.CandidateCreate}?hiringId=${hiringAuthorityId}`);
  };

  const handleLinkCandidateCompleted = async linkCandidate => {
    setUiState(prevState => ({ ...prevState, isLinkCandidateOpen: false }));
    if (linkCandidate) {
      history.push(
        EntityRoutes.CandidateProfile.replace(':id', linkCandidate.relation.candidate_id)
      );
    }
  };

  const handleCandidateClosed = type =>
    uiState[type] && setUiState(prevState => ({ ...prevState, [type]: false }));

  const handleUnlinkCandidateClick = () => {
    const candidateId = results?.relation?.candidate?.id;
    const hiringName = results?.full_name;

    if (candidateId) {
      dispatch(
        confirm({
          severity: 'warning',
          title: strings.inventoryProfiles.sections.candidates.unlinkHiringDialog.title,
          message: strings.formatString(
            strings.inventoryProfiles.sections.candidates.unlinkHiringDialog.message,
            {
              hiringName
            }
          ),
          confirmButtonText: 'Unlink Profiles',
          cancelButtonText: 'Keep Profiles',
          onConfirm: async ok => {
            try {
              if (!ok) return;

              setUiState(prevState => ({ ...prevState, isLoading: true }));

              const response = await API.delete(
                `${Endpoints.HiringAuthorities}/${hiringAuthorityId}/${Endpoints.Candidates}/${candidateId}/${Endpoints.Unlink}`
              );

              if (response && response.status === 204) {
                dispatch(
                  showAlert({
                    severity: 'success',
                    title: 'Candidate Unlinked Successfully',
                    body: `${hiringName} was unlinked from the Candidate Profile`
                  })
                );

                handleProfileStateUpdate({ relation: { candidate_id: null, candidate: null } });
              }
            } catch (error) {
              dispatch(
                showAlert({
                  severity: 'error',
                  title: 'Unlink Candidate',
                  body: getErrorMessage(error)
                })
              );
            } finally {
              setUiState(prevState => ({ ...prevState, isLoading: false }));
            }
          }
        })
      );
    }
  };

  const customMenuItems = [
    ...SearchProjectsMenuItems,
    {
      title: strings.inventoryProfiles.common.candidateAddition,
      action: () => handleCreateCandidateClick(),
      visible: !hiringAuthorityRelatedWithCandidate
    },
    {
      title: 'Link with existing Candidate',
      action: () => handleLinkCandidateClick('isLinkCandidateOpen'),
      visible: !hiringAuthorityRelatedWithCandidate
    },
    {
      title: 'Unlink Candidate Profile',
      action: () => handleUnlinkCandidateClick(),
      visible: hiringAuthorityRelatedWithCandidate
    }
  ];

  const { optOutDate } = getOptOutDate(results);

  const summaryLabels = [
    { title: strings.inventoryProfiles.common.optOut.creation, content: optOutDate }
  ];

  return (
    <ProfileUiStateManager
      status={status}
      responseStatusCode={state.responseStatusCode}
      entityRoute={EntityRoutes.Contacts}
      onRefreshProfileClick={refreshData}
      entity={HiringAuthorityEntity}
      profileStatusMode={{
        loading: (
          <InventoryProfileLayout
            entityType={HiringAuthorityEntity}
            isLoading={isLoading}
            isChangeLogsTabEnabled={isChangeLogsTabEnabled}
            contentPageLayoutProps={{ className: layoutClassName }}
            results={results}
            enableUrlNavigation={enableUrlNavigation}
            ProfileTabsProps={{
              endpoint,
              profileTabProps: tabsProps
            }}
          />
        ),
        success: (
          <>
            <InventoryProfileLayout
              activityLabel={formattedDate}
              addItemLabel="Add a Colaborator"
              contentPageLayoutProps={{ className: layoutClassName }}
              additionalHeaderActions={
                <ToggleProfileModeButton
                  results={results}
                  mode={EntityType.HiringAuthority}
                  {...profileModeProps}
                />
              }
              company={results?.company}
              entityType={HiringAuthorityEntity}
              headerCardProps={headerProps}
              isLoading={isLoading}
              isChangeLogsTabEnabled={isChangeLogsTabEnabled}
              results={results}
              enableUrlNavigation={enableUrlNavigation}
              summaryLabels={summaryLabels}
              profileMenuProps={{
                onEditClick: handleEditClick,
                customMenuItems
              }}
              ProfileTabsProps={{
                endpoint,
                profileTabProps: tabsProps
              }}
            />
            <Drawer open={uiState.isEditOpen && isProfileLoaded} onClose={handleEditClosed}>
              <div role="presentation">
                <HiringAuthorityDrawer
                  type={EntityType.Company}
                  isEditing={uiState.isEditOpen}
                  companyId={results?.company_id}
                  hiringAuthority={results}
                  endpoint={`${Endpoints.Companies}/${results?.company_id}/${Endpoints.HiringAuthorities}/${profileId}`}
                  onHACompleted={handleEntityEditionCompleted({
                    handleEditCompleted,
                    enableUrlNavigation,
                    onEditionCompleted
                  })}
                  onHAClosed={handleEditClosed}
                />
              </div>
            </Drawer>
            <Drawer
              open={uiState.isLinkCandidateOpen && hiringAuthorityId}
              onClose={() => handleCandidateClosed('isLinkCandidateOpen')}
            >
              <LinkCandidate
                hiringAuthorityId={hiringAuthorityId}
                onClose={() => handleCandidateClosed('isLinkCandidateOpen')}
                onCompleted={handleLinkCandidateCompleted}
              />
            </Drawer>
            <SearchProjectForms />
            {uiState.isSendoutOpen && uiState.selectedSendoutId && isProfileLoaded && (
              <Drawer open onClose={handleSendoutClosed}>
                <SendoutManager
                  id={uiState.selectedSendoutId}
                  onEdit={handleSendoutCompleted}
                  onNavigate={handleSendoutNavigation}
                  onDelete={handleSendoutCompleted}
                  onClose={handleSendoutClosed}
                />
              </Drawer>
            )}
          </>
        )
      }}
    />
  );
};

export default HiringAuthorityProfile;
