import moment from 'moment';
import { DateFormats } from 'UI/constants/defaults';

import { CHAT_FAKE_RESPONSE } from './tests/fixtures';

export const fetchChat = async () => {
  return new Promise(resolve => {
    console.log('fetchChat');

    setTimeout(() => {
      resolve(CHAT_FAKE_RESPONSE);
    }, 2000);
  });
};

export const sendMessageToServer = async message => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(message);
    }, 2500);
  });
};

export const getDateLabel = timeString => {
  const date = moment(timeString).local();
  if (!date.isValid()) {
    return 'Invalid Date';
  }

  const now = moment();

  const startOfToday = now.clone().startOf('day');
  const startOfYesterday = startOfToday.clone().subtract(1, 'days');

  if (date.isSame(startOfToday, 'day')) {
    return 'Today';
  }

  if (date.isSame(startOfYesterday, 'day')) {
    return 'Yesterday';
  }

  return moment(date).format(DateFormats.SimpleDate);
};

export const groupMessagesByDate = chatArray => {
  if (!Array.isArray(chatArray)) return {};

  return chatArray.reduce((acc, message) => {
    const date = message.timestamp.split('T')[0];

    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);

    return acc;
  }, {});
};

export const addMessageToChat = (chat, message) => {
  const { date = null } = message;

  if (!date) return chat;

  return {
    ...chat,
    [date]: [...(chat[date] ?? []), message]
  };
};

export const updateMessageStatus = ({ chat, messageToUpdate, status }) => {
  const { date } = messageToUpdate;

  const updatedMessages = {
    ...chat,
    [date]: chat[date].map(message =>
      message.id === messageToUpdate.id
        ? {
            ...message,
            status
          }
        : message
    )
  };

  return updatedMessages;
};
