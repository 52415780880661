import React from 'react';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { MESSAGE_DIRECTION } from '../chat.constants';

const MAX_WIDTH_MESSAGE = 320;

export const MessageRow = styled(({ direction: _direction, ...other }) => <div {...other} />)(
  ({ direction, theme }) => ({
    display: 'flex',
    justifyContent: direction === MESSAGE_DIRECTION.inbound ? 'flex-start' : 'flex-end',
    marginBottom: theme.spacing(1)
  })
);

export const MessageContainer = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center'
});

export const MessageBubble = styled(({ direction: _direction, ...other }) => <div {...other} />)(
  ({ direction, theme }) => {
    const isOutbound = direction === MESSAGE_DIRECTION.outbound;
    return {
      backgroundColor: isOutbound ? theme.palette.primary.main : theme.palette.grey[300],
      borderRadius: isOutbound ? theme.spacing(2.5, 2.5, 0, 2.5) : theme.spacing(2.5, 2.5, 2.5, 0),
      marginLeft: isOutbound ? theme.spacing(1) : 0,
      marginTop: theme.spacing(0.5),
      maxWidth: MAX_WIDTH_MESSAGE,
      padding: theme.spacing(2)
    };
  }
);

export const MessageText = styled(
  ({ direction: _direction, isHourText: _isHourText, ...other }) => <Typography {...other} />
)(({ direction, isHourText, theme }) => {
  const isOutbound = direction === MESSAGE_DIRECTION.outbound;

  return {
    color: isOutbound ? theme.palette.common.white : theme.palette.common.black,
    fontSize: isHourText ? 14 : 16,
    margin: 0,
    marginBottom: theme.spacing(0.5),
    textAlign: isOutbound ? 'right' : 'left',
    whiteSpace: 'pre-wrap'
  };
});

export const DateTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  textAlign: 'center'
}));
