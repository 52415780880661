import React from 'react';
import { Chart } from 'react-google-charts';

import { DEFAULT_OPTIONS } from './LineSteppedAreaChart.utils';

export const LineSteppedAreaChart = ({ data, options }) => {
  return (
    <Chart
      chartType="LineChart"
      data={data}
      height="320px"
      legendToggle
      options={{ ...DEFAULT_OPTIONS, ...options }}
      width="100%"
    />
  );
};
