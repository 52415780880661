// @flow
import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import { AppInsightsErrorBoundary } from 'AppInsights';
import strings from 'strings';
import type { DrawerContentLayoutProps } from 'types/app';
import ConditionalActionWrapper from 'UI/components/atoms/ConditionalWrapper';
import { FPCardHeader } from 'UI/components/atoms/FPCard';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import { When } from 'UI/components/atoms/When';
import FooterActionsControlsLegacy from 'UI/components/molecules/FooterActionsControlsLegacy';
import FPButtonBuilder from 'UI/components/molecules/FPButtonBuilder';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import ErrorDiagnosticsPanel from 'UI/components/organisms/ErrorDiagnosticsPanel';
import { CloseIcon, colors } from 'UI/res';
import { nestTernary } from 'UI/utils';
import { navigationPressetBuilder } from 'UI/utils/inventory';

import { useStyles } from './styles';

const GRID_SIZE = 2;

const DrawerContentLayout = (props: DrawerContentLayoutProps) => {
  const {
    additionalHeaderButtons,
    children,
    containerComponent,
    contentProps,
    customPrimaryButton,
    CustomFooter,
    drawerProps,
    errorRender,
    footerActionsProps,
    headerProps,
    initialText,
    isBottomToolbarNeeded,
    isInnerDrawer,
    isSaveButtonMode,
    isTopToolbarNeeded,
    moreActions,
    onBack,
    onClose,
    onProgressText,
    onSecondaryButtonClick,
    onSubmit,
    onSuccessText,
    primaryProps,
    secondaryButtonText,
    subheader,
    title,
    titleLabelProps,
    uiState,
    variant,
    FPButtonBuilderProps: { GridContainerProps },
    NavigationControlsProps: {
      isGoBackEnabled,
      isGoNextEnabled,
      enableNavigationControls,
      onGoBack,
      onGoNext,
      isContentLoading
    },
    ...rest
  } = props;
  const shouldWrapInDrawer = !!drawerProps;

  const classes = useStyles(props, variant, isInnerDrawer);

  const handleSecondaryClick = () => {
    if (onSecondaryButtonClick) {
      onSecondaryButtonClick();
    } else if (onClose) {
      onClose();
    }
  };

  const renderContent = () =>
    uiState.isFormLoading ? (
      <div className={classes.wrapper}>
        <CircularProgress />
      </div>
    ) : (
      nestTernary(
        uiState.isError && errorRender,
        errorRender,
        <AppInsightsErrorBoundary onError={ErrorFallback}>{children}</AppInsightsErrorBoundary>
      )
    );

  const hasMoreActions = moreActions && moreActions.length > 0;

  const saveButtonProps = isSaveButtonMode && {
    isSaving: uiState.isSaving,
    isSuccess: uiState.isSuccess,
    initialText,
    onProgressText,
    onSuccessText
  };

  const buttonsConfig = {
    secondaryButton: !enableNavigationControls && {
      props: {
        text: secondaryButtonText,
        onClick: handleSecondaryClick,
        variant: 'outlined'
      }
    },
    primaryButton: {
      mode: isSaveButtonMode ? 'save' : 'default',
      props: {
        ...saveButtonProps,
        disabled:
          uiState.isError || uiState.isFormDisabled || uiState.isFormLoading || uiState.isSaving,
        ...primaryProps
      }
    },
    moreActions: hasMoreActions && {
      props: { title: 'More actions', isIconButton: true, menuItems: moreActions, edge: 'end' },
      mode: 'menu'
    }
  };

  const loading = isContentLoading || uiState.isFormLoading;

  const buttonsList = [
    ...navigationPressetBuilder({
      onGoBack,
      onGoNext,
      isGoBackEnabled: loading || !isGoBackEnabled,
      isGoNextEnabled: loading || !isGoNextEnabled,
      GridItemProps: { classes: { item: classes.gridItem }, md: GRID_SIZE }
    }),
    buttonsConfig.secondaryButton,
    buttonsConfig.primaryButton,
    buttonsConfig.moreActions
  ];

  const buttonsDefinitions = buttonsList.filter(item => item?.props);

  const multipleRecords = isGoBackEnabled || isGoNextEnabled;

  const closeButton = (
    <FPIconButton tooltipProps={{ placement: 'bottom', title: 'Close' }} onClick={onClose}>
      <CloseIcon width={20} height={20} fill={colors.completeBlack} />
    </FPIconButton>
  );

  return (
    <ConditionalActionWrapper
      condition={shouldWrapInDrawer}
      wrapper={(c = children) => <Drawer {...drawerProps}>{c}</Drawer>}
    >
      <Box
        id="drawer-form"
        component={containerComponent}
        onSubmit={containerComponent === 'form' ? onSubmit : null}
        className={classes.drawerContainer}
        {...rest}
      >
        {!uiState.isFormLoading && !uiState.isError && title && (
          <FPCardHeader
            className={classes.headerContainer}
            classes={{ action: classes.headerActions }}
            title={
              <TitleLabel
                fontSize={28}
                text={title}
                backNavigation={uiState.showBackNavigation}
                onCustomBackClick={onBack}
                mode="customAction"
                backNavigationProps={{
                  edge: 'start'
                }}
                {...titleLabelProps}
              />
            }
            subheader={subheader}
            action={
              isTopToolbarNeeded && (
                <>
                  {additionalHeaderButtons}
                  {!isInnerDrawer && closeButton}
                </>
              )
            }
            {...headerProps}
          />
        )}

        <When condition={!title && !uiState.isFormLoading && !uiState.isError}>
          <div className={classes.closeButton}>{closeButton}</div>
        </When>
        <div className={classes.drawerContent} {...contentProps}>
          {renderContent()}
        </div>
        {isBottomToolbarNeeded && (
          <div className={classes.drawerToolbar}>
            {multipleRecords && enableNavigationControls ? (
              <FPButtonBuilder
                GridContainerProps={GridContainerProps}
                buttonDefinitions={buttonsDefinitions}
              />
            ) : (
              <>
                {CustomFooter || (
                  <>
                    <FooterActionsControlsLegacy
                      saveAction={isSaveButtonMode}
                      containerClassName={classes.actionsContainer}
                      secondaryAction={handleSecondaryClick}
                      secondaryProps={{
                        text: secondaryButtonText,
                        fullWidth: true
                      }}
                      primaryProps={{
                        className: classes.primaryButton,
                        isSaving: uiState.isSaving,
                        isSuccess: uiState.isSuccess,
                        disabled:
                          uiState.isError ||
                          uiState.isFormDisabled ||
                          uiState.isFormLoading ||
                          uiState.isSaving,
                        initialText,
                        onProgressText,
                        onSuccessText,
                        ...primaryProps
                      }}
                      customPrimaryButton={
                        customPrimaryButton
                          ? customPrimaryButton({
                              classes: {
                                primaryButton: classes.primaryButton
                              },
                              disabled:
                                uiState.isFormDisabled ||
                                uiState.isFormLoading ||
                                uiState.isSaving ||
                                uiState.isError
                            })
                          : null
                      }
                      {...footerActionsProps}
                    />
                    {moreActions && moreActions.length > 0 && (
                      <FPButtonMenu
                        title="More actions"
                        isIconButton
                        menuItems={moreActions}
                        edge="end"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}
      </Box>
    </ConditionalActionWrapper>
  );
};

DrawerContentLayout.defaultProps = {
  additionalHeaderButtons: undefined,
  containerComponent: 'form',
  contentProps: null,
  customPrimaryButton: null,
  drawerProps: undefined,
  errorRender: null,
  footerActionsProps: {},
  FPButtonBuilderProps: {
    buttonDefinitions: undefined,
    GridContainerProps: undefined,
    GridItemProps: undefined
  },
  headerProps: null,
  initialText: 'Save',
  isBottomToolbarNeeded: true,
  isInnerDrawer: false,
  isSaveButtonMode: true,
  isTopToolbarNeeded: true,
  moreActions: [],
  onBack: undefined,
  onProgressText: 'Saving',
  onSecondaryButtonClick: null,
  onSubmit: () => {},
  onSuccessText: 'Saved',
  primaryProps: undefined,
  secondaryButtonText: 'Close',
  subheader: undefined,
  uiState: {
    isError: false,
    isFormDisabled: false,
    isFormLoading: false,
    isReadOnly: false,
    isSaving: false,
    isSuccess: false,
    showBackNavigation: false
  },
  NavigationControlsProps: {
    enableNavigationControls: false,
    onGoNext: () => null,
    onGoBack: () => null,
    isGoBackEnabled: false,
    isGoNextEnabled: false
  },
  variant: 'white'
};

const ErrorFallback = ({ error }) => (
  <>
    <Typography variant="h5" gutterBottom>
      {strings.shared.ui.unknownError.title}
    </Typography>
    {error && <ErrorDiagnosticsPanel error={error?.message} />}
  </>
);

export default DrawerContentLayout;
