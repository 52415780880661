import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import { DateFormats } from 'UI/constants/defaults';
import { localTimeFormatter } from 'UI/utils';

import { MESSAGE_STATUS } from '../chat.constants';

import { MessageBubble, MessageContainer, MessageRow, MessageText } from './Message.styled';

const statusMessageRender = {
  [MESSAGE_STATUS.sending]: <CircularProgress size={16} />,
  [MESSAGE_STATUS.success]: <DoneIcon aria-label="Done Icon" color="primary" />,
  [MESSAGE_STATUS.error]: <ErrorIcon aria-label="Error Icon" color="error" />
};

export const Message = ({ message, direction, timestamp, status }) => {
  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    if (status === MESSAGE_STATUS.success) {
      setTimeout(() => {
        setShowIcon(false);
      }, 3000);
    }
  }, [status]);

  return (
    <MessageRow direction={direction}>
      <MessageContainer>
        {showIcon && statusMessageRender[status] && statusMessageRender[status]}

        <MessageBubble direction={direction}>
          <MessageText direction={direction}>{message}</MessageText>

          <MessageText variant="body2" direction={direction} isHourText>
            {localTimeFormatter(timestamp, DateFormats.SimpleTime)}
          </MessageText>
        </MessageBubble>
      </MessageContainer>
    </MessageRow>
  );
};
