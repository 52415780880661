// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
/** API / EntityRoutes / Endpoints / EntityType */
import useDatatable, {
  addRingCentralContactToStore,
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import useMultipleSelection, { SearchProjectBar } from 'hooks/multipleSelection';
import { useSearchProject } from 'hooks/searchProject';
import { useQuickViewPreview } from 'hooks/useQuickViewPreview';
import { When } from 'UI/components/atoms/When';
/** Atoms, Components and Styles */
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
/** Components */
import DataTable from 'UI/components/organisms/DataTable';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
/** API / EntityRoutes / Endpoints / EntityType */
import { backNavigateListConfig } from 'UI/constants/config';
import { CommunicationActionPreset, PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { createCustomStaticRanges } from 'UI/utils';

import {
  ColumnsDefinitions,
  DateFilters,
  FiltersGroups,
  PeriodColumnBySection,
  VirtualProps
} from './columns';

const pageKey = 'companies-list';

const CompaniesList = ({ location }) => {
  const dispatch = useDispatch();

  const orderByOptions = {
    column: 'name',
    direction: 'asc'
  };

  const initialPreferences = getColumnPreferences(pageKey, 0, orderByOptions, ColumnsDefinitions);

  const [dateTypeFilter, setDateTypeFilter] = useState(DateFilters[0]);

  const {
    columnPreferences,
    count,
    data,
    filters,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handleOneFilterChange,
    handlePageChange,
    handlePeriodChange,
    handlePerPageChange,
    handleSectionChange,
    listState,
    mainOptions,
    queryParams
  } = useDatatable({
    key: pageKey,
    endpoint: Endpoints.Companies,
    entityName: 'Companies',
    entityType: EntityType.Company,
    initialPreferences,
    columnsDefinitions: ColumnsDefinitions,
    orderByOptions,
    virtualProps: VirtualProps,
    periodDefaultValue: null,
    periodFilterColumn: PeriodColumnBySection,
    sendDateWithTimezone: true,
    shouldScrollOnNavigate: true
  });

  useEffect(() => {
    document.title = PageTitles.Company;
  }, []);

  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword, userFilter } =
    listState;

  const dataForSelection = useMemo(
    () => data.map(({ id }) => ({ id, type_id: 6, type: 'Company' })),
    [data]
  );

  const {
    handleRowSelection,
    selectedIndexesInPage,
    multiSelectComponents,
    totalSelected,
    filteredItems
  } = useMultipleSelection({ data: dataForSelection, count, perPage });

  const { SearchProjectAction, SearchProjectForms } = useSearchProject({
    filteredItems,
    queryParams
  });

  const handleAction = ({ rowData }) =>
    addRingCentralContactToStore({
      rowData,
      columns: ColumnsDefinitions,
      dispatch,
      role: EntityType.Company
    });

  const { entityId, quickViewState, listViewPreset, quickViewEntity, toggleQuickViewPreview } =
    useQuickViewPreview({ columns, data, entityType: EntityType.Company });

  const phoneActionButtons = buildRowActionsRendererDefinition({
    actions: [...CommunicationActionPreset, listViewPreset],
    namespace: EntityType.Company,
    handleAction
  });

  const getCustomToolbar = useCallback(
    () => (
      <SearchProjectBar total={totalSelected}>
        <SearchProjectAction />
      </SearchProjectBar>
    ),
    [totalSelected]
  );

  const handleDateTypeFilterChange = (name, value) => {
    setDateTypeFilter(value);
    handleSectionChange && handleSectionChange(name, value.id);
  };

  const finalColumns = getColumnsToRender([...columns, phoneActionButtons]);

  const handleTabRefresh = async () => {
    await getData();
  };

  return (
    <FiltersLayout
      title="Companies"
      section={EntityType.Company}
      listSelector={
        <AutocompleteSelect
          name="userFilter"
          placeholder="Companies to show"
          selectedValue={userFilter || mainOptions[0]}
          onSelect={handleOneFilterChange}
          defaultOptions={mainOptions}
        />
      }
      defaultRange={null}
      extraSelector={
        <AutocompleteSelect
          name="dateTypeFilter"
          placeholder="Filter By"
          selectedValue={dateTypeFilter}
          onSelect={handleDateTypeFilterChange}
          defaultOptions={DateFilters}
          disableClearable
        />
      }
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      filters={filters}
      isSideMenuOpen={isSideMenuOpen}
      isLoading={showWholeSkeleton}
      groups={FiltersGroups}
      enableStore={false}
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      titleLabelProps={backNavigateListConfig}
      onPeriodChange={handlePeriodChange}
    >
      <DataTable
        isExpandable
        loading={showWholeSkeleton}
        refreshing={isLoading}
        data={data}
        columns={finalColumns}
        count={count}
        filter={false}
        page={page}
        rowsPerPage={perPage}
        searchText={keyword}
        sortOrder={{ name: orderBy, direction }}
        columnOrder={columnOrder?.length ? columnOrder : undefined}
        draggableColumns={{
          enabled: true
        }}
        enableCustomFilters
        onToggleFilters={handleFiltersToggle}
        onSearchTextChange={handleKeywordChange}
        onColumnSortChange={handleColumnSortChange}
        onPerPageClick={handlePerPageChange}
        onPageClick={handlePageChange}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnOrderChange={handleColumnOrderChange}
        onRowSelectionChange={handleRowSelection}
        rowsSelected={selectedIndexesInPage}
        selectToolbarPlacement="none"
        customToolbar={getCustomToolbar}
        components={multiSelectComponents}
      />
      <SearchProjectForms />
      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          onClose={toggleQuickViewPreview}
          entityType={quickViewEntity}
          itemsToNavigate={data}
          id={entityId}
          location={location}
          onEditionCompleted={handleTabRefresh}
        />
      </When>
    </FiltersLayout>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAlert: alert => dispatch(showAlert(alert))
  };
};

export default connect(null, mapDispatchToProps)(CompaniesList);
