import React from 'react';
import { StatsItemContainer } from 'features/command-center/components/shared';

import { StatsCard } from '../../shared/StatsCard';

import {
  StyledDivider,
  SummaryCardContainer,
  TitleHeadline
} from './RegionalDirectorSummaryCard.styled';

export const RegionalDirectorSummaryCard = ({
  title,
  urlPhoto,
  detailsUrl,
  healthStats,
  financeStats
}) => {
  return (
    <SummaryCardContainer>
      <StatsCard
        header={urlPhoto && <img src={urlPhoto} alt={title ?? 'Regional Director'} />}
        detailsUrl={detailsUrl}
      >
        <TitleHeadline variant="h5" component={detailsUrl && 'a'} href={detailsUrl}>
          {title}
        </TitleHeadline>
        <StyledDivider role="separator" />
        <StatsItemContainer items={healthStats} variant="stacked" />
        <StyledDivider role="separator" />
        <StatsItemContainer items={financeStats} variant="stacked" />
      </StatsCard>
    </SummaryCardContainer>
  );
};
