import LocalizedStrings from 'localized-strings';

const strings = new LocalizedStrings({
  en: {
    shared: {
      app: {
        name: 'FortPac',
        support: 'Contact FortPac Support'
      },
      buttons: {
        previous: 'Previous',
        next: 'Next'
      },
      ui: {
        awesome: 'Awesome',
        cancel: 'Cancel',
        close: 'Close',
        confirm: 'Please confirm',
        error: 'Error',
        fillIn: 'Fill In',
        goBack: 'Go Back',
        back: 'Back',
        keepRemoveButtonsCopies: {
          cancelButtonText: 'Keep',
          confirmButtonText: 'Remove'
        },
        keepDeleteButtonCopies: {
          cancelButtonText: 'Keep',
          confirmButtonText: 'Delete'
        },
        ok: 'Ok',
        save: 'Save',
        submit: 'Submit',
        update: 'Update',
        approve: 'Approve',
        continue: 'Continue',
        unknownError: {
          title: 'Something went wrong',
          body: 'Please copy the following information and report it to',
          targetGlip: `Fortpac Support's Glip`,
          action: 'Go Back',
          diagnosticsError: 'Error',
          diagnosticsUrl: 'URL',
          version: 'Version'
        },
        view: 'View',
        edit: 'Edit',
        working: 'Working...',
        yes: 'Yes',
        no: 'No',
        reset: 'Reset {item}',
        clear: 'Clear'
      },
      fields: {
        required: 'Fields marked with * are required',
        addedDate: 'Added Date',
        city: 'City',
        coach: 'Team',
        company: 'Company',
        legalName: 'Legal Name',
        compensationRange: 'Compensation Range',
        createdAt: 'Created at',
        currentCompany: 'Current Company',
        ext: 'Ext',
        email: 'Email',
        fullName: 'Full Name',
        functionalTitle: 'Functional Title',
        functionalTitles: 'Functional Titles',
        hiringAuthority: 'Hiring Authority',
        industry: 'Industry',
        industries: 'Industries',
        location: 'Location',
        otherPhone: 'Other phone',
        otherEmail: 'Other email',
        phone: 'Phone',
        recruiter: 'Recruiter',
        regional: 'Regional Director',
        specialty: 'Specialty',
        subspecialty: 'Subspecialty',
        state: 'State',
        source: 'Source',
        status: 'Status',
        title: 'Title',
        type: 'Type',
        zip: 'Zip'
      },
      validations: {
        invalid: 'This field is not valid',
        otherEmail: 'Email and Other Email cannot be the same',
        phoneDigits: 'Please enter at least 4 digits',
        duplicatePhones:
          'There are duplicate phone numbers. Please delete or update duplicate entries.'
      },
      filters: {
        industries: 'Industries',
        team: 'Team',
        location: 'Location',
        optOutPlaceHolder: 'Email Subscription Status',
        excludeOptOut: 'Exclude Opt Outs',
        showOptOut: 'Show Only Opt Outs',
        emailValidationPlaceHolder: 'Email Validation',
        validEmails: 'Valid Emails',
        invalidEmails: 'Invalid Emails',
        filterBy: 'Filter by',
        searchSpecificPILCities: 'Include only selected cities (excludes statewide recruiters).',
        searchSpecificPILStates: 'Include only statewide recruiters.',
        numberOfContacts: 'Number of Contacts',
        numberOfEmployees: 'Number of Employees',
        revenue: 'Revenue'
      },
      files: {
        defaultNofileSelectorText: 'No files attached',
        defaultSelectorDescription: 'File not uploaded yet?'
      },
      loading: {
        title: 'Loading content...',
        subtitle: 'Please hang on'
      },
      linkCompany: {
        title: `{capitalizedType} Not Related With Company`,
        noCompany: `There are no company suggestions based on your {type}'s information. Please select or add one.`,
        addCompany: `All {type}s must be related with a company.`,
        suggestions: `Here are some company suggestions based on your {type}'s information. Please select one.`,
        selectCompany: `Select or add the company this {type} is related with:`
      },
      errors: {
        defaultMessage: 'An error has ocurred. Please try again.',
        loadFail: 'Something went wrong!',
        unauthorized: 'You do not have permissions to see this information',
        serverError: {
          title: 'Server Error',
          subtitle:
            'Something went wrong while completing your request. Please try again. If the problem persists, contact FortPac Support.'
        },
        profiles: {
          candidate: {
            notFound: {
              title: 'Candidate Not Found',
              subtitle:
                "The Candidate you're looking for is not in the database. Try searching for a different Candidate"
            }
          },
          joborder: {
            notFound: {
              title: 'Job Order Not Found',
              subtitle:
                "The Job Order you're looking for is not in the database. Try searching for a different Job Order"
            }
          },
          hiringAuthority: {
            notFound: {
              title: 'Hiring Authority Not Found',
              subtitle:
                "The Hiring Authority you're looking for is not in the database. Try searching for a different Hiring Authority"
            }
          },
          company: {
            notFound: {
              title: 'Company Not Found',
              subtitle:
                "The Company you're looking for is not in the database. Try searching for a different Company"
            }
          },
          name: {
            notFound: {
              title: 'Contact Not Found',
              subtitle:
                "The Contact you're looking for is not in the database. Try searching for a different Contact"
            }
          }
        }
      },
      emptyState: {
        title: "We couldn't find any information.",
        subtitle:
          "It seems like there are no results for what you're looking for. Please try again",
        cardWrapper: {
          title: 'Loading content.',
          subtitle: 'Please hang on.'
        }
      },
      rowActions: {
        menuItems: {
          remove: {
            title: 'Remove From Search Project'
          }
        }
      },
      searchGlobal: {
        candidate: 'Search by Name, Email, or Position',
        jobOrder: 'Search by Position, or Company',
        company: 'Search by Company Name',
        contact: 'Search by Name, or Email',
        pil: 'Search by industry, Specialty or Subspecialty',
        pilAndPosition: 'Search by industry, Specialty, Subspecialty or position'
      },
      requests: {
        cancelTokenMessage: 'Operation canceled due to new request.'
      },
      dataSheet: {
        footer: {
          buttons: {
            saveDraft: 'Save Progress',
            savingDraft: 'Saving Progress'
          }
        }
      }
    },
    navBar: {
      versionBarButton: `What's New?!`,
      artificialIntelligenceButton: 'gpac AI Generator',
      extensionButton: 'Fortpac Extension',
      trainingButton: 'gpac U Training',
      help: 'Help & Information Center',
      versionBar: `You’re using an old version of <b> Fortpac ({version}). </b> Save your work and refresh the page to get the latest version`
    },
    home: {
      subtitle: 'Take action on your inventory at FortPac now!',
      title: 'Daily Tracker',
      headerActions: {
        filterByNewMembers: 'Filter by Recruiters from 0-3 months'
      },
      shared: {
        dateRange: {
          today: 'Today',
          week: 'Current Week',
          month: 'Current Month',
          yearToDate: 'Year to Date'
        }
      },
      sections: {
        weeklyStats: {
          sendouts: {
            title: 'Sendouts'
          },
          convertedSendovers: {
            title: 'Converted sendovers'
          },
          bulks: {
            title: 'Bulks'
          },
          calls: {
            title: 'Daily Call Time Goal (Minutes)'
          },
          candidatesCard: {
            title: 'New Candidates'
          },
          jobOrdersCard: {
            title: 'New Job Orders'
          }
        },
        dailyStats: {
          bulks: 'Bulks *',
          calls: 'Calls *',
          currentTeamMembers: 'Current Members',
          texts: 'Sent Texts *',
          newCandidates: 'New Candidates',
          newItems: 'New Items',
          newJobOrders: 'New Job Orders',
          newMembers: 'Members from 0-3 months',
          placements: 'Placements',
          sendouts: 'Sendouts',
          sendovers: 'Sendovers',
          sentAgreements: 'Sent Agreements',
          drawBalance: 'Draw Balance',
          drawBalancePeriod: 'Last Processed Pay Period',
          placementsYTD: 'Placements YTD',
          fallOffsYTD: 'Fall Offs YTD'
        },
        dailyStatsOverview: {
          footer: {
            bulks: '* Bulk activity updates every hour approximately. Last updated on {date} at ',
            call: '* Calls and Min may update from 30 min up to 1 hour after the call. Last updated on {date} at ',
            details: `<b>IMPORTANT:</b> If you haven't seen any expected activity update, please refresh FortPac.`,
            externalTools: '* Inmails last updated on {date} at ',
            phonePerformanceRules: 'Learn more about phone performance rules',
            text: '* Texts update every hour. Last updated on {date} at ',
            title:
              '<b>Stats are live</b>, meaning they update on time <b>except in the following cases:</b>'
          }
        },
        drawBalance: {
          details: 'More details on Power BI',
          period: 'Last processed pay period'
        },
        noActivityTotals: {
          candidates: 'Alpha Candidates with No Activity',
          candidatesUnassigned: 'Alpha Candidates Unassigned (from Terms)',
          searchAssignments: 'Search Assignments with No Activity',
          searchAssignmentsUnassigned: 'Search Assignments Unassigned (from Terms)'
        },
        noUpdatedTotals: {
          dailyInterviews: 'Interviews Today',
          sendouts: 'Sendouts Not Updated',
          sendovers: 'Sendovers Not Updated',
          feeAgreements: 'Agreements Pending HA Signature'
        },
        phonePerformanceStats: {
          phonePerformancePercentage: {
            title: 'Phone Performance'
          },
          calls: {
            title: 'Calls'
          },
          time: {
            title: 'Minutes'
          }
        },
        regionalOverview: {
          tabs: {
            todayActivity: `Today's Activity`,
            placementsAndFallOffs: 'Placements & Fall Offs YTD',
            ratios: 'Ratios YTD',
            hireAndTerms: 'Hires and Terms YTD'
          }
        }
      },
      emptyState: {
        team: {
          title: `Check your teams' daily stats`,
          subtitle: 'Select one of your teams to display its current metrics'
        },
        recruiter: {
          title: 'Information not found.',
          subtitle: `We couldn't retrieve any data at the moment. Please refresh the page.
                      If the problem persists, contact FortPac Support.`
        }
      },
      selectRegionAndTeam: 'Filter Stats by Region and/or Team',
      selectTeam: 'Select the team whose stats you want to see'
    },
    creationItems: {
      required: {
        country: 'Country: State *',
        city: 'City *',
        zip: 'Zip Code *'
      },
      noRequired: {
        country: 'Country: State',
        city: 'City',
        zip: 'Zip Code'
      }
    },
    placements: {
      title: 'Placements',
      listing: {
        validations: {
          title: 'Pending Approvals',
          emptyTitle: 'No Validations to Show',
          emptySubtitle: 'All your Placement validations will appear here'
        },
        estimates: {
          title: 'Pending Estimate',
          emptyTitle: 'No Estimates to Show',
          emptySubtitle: 'All estimates will appear here'
        },
        pendingInvoice: {
          title: 'Pending Invoice',
          emptyTitle: 'No Placements to Show',
          emptySubtitle: 'All Placements pending to be invoiced will appear here'
        },
        invoiced: {
          title: 'Invoiced',
          emptyTitle: 'No invoiced Placements to Show',
          emptySubtitle: 'Invoiced Placements will appear here'
        },
        falloff: {
          title: 'Fall Off',
          emptyTitle: 'No fall offs to Show',
          emptySubtitle: 'Fall offs will appear here'
        },
        all: {
          title: 'All',
          emptyTitle: 'No Placements to Show',
          emptySubtitle: 'Try changing your filters'
        }
      },
      referenceRelease: {
        prompt:
          'To create a Placement, make sure you have sent the Reference Release Email to the candidate first',
        helper:
          "To create a Placement, make sure the Candidate's Recruiter has sent the Reference Release Email.",
        secondaryHelper: 'IMPORTANT: Reference Release Emails can now be sent through Fortpac.',
        alreadySent: 'Already sent it outside FortPac?',
        continue: 'Continue creating the Placement',
        send: 'Send Reference Release'
      },
      sendouts: {
        requirement: 'A Sendout needs to be created before creating a Placement',
        create: 'Create a Sendout',
        select: 'Please select a Sendout'
      },
      fields: {
        sendout: 'Select a Sendout',
        feeAgreement: 'Fee Agreement',
        feePercent: 'Fee %',
        requestFeeChangePrompt: 'Request Fee % change',
        agreementType: 'Agreement Type',
        firstYear: 'First Year',
        salary: 'Salary',
        compensation: 'Compensation',
        guaranteeDays: 'Guarantee Days',
        reasonForRequestChange: 'Reason for Fee or Guarantee Days change',
        requestGuaranteeDaysChangePrompt: 'Request Guarantee Period change',
        startDate: 'Start Date',
        fullFeeAmount: 'Full Fee Amount (USD)',
        monthlyAmount: 'Monthly Amount',
        serviceMonths: 'Service Months',
        hiringAuthority: 'Hiring Authority',
        jobOrder: 'Job Order',
        jobOrderSource: 'Job Order Source',
        candidate: 'Candidate',
        candidateDataSource: 'Candidate Source',
        company: 'Company',
        companyPhone: 'Company Phone',
        companyPhoneExtension: 'Company phone extension',
        billToCompany: 'Company',
        billToCompanyHint:
          'Please confirm with your Hiring Authority the Company that will be invoiced',
        billToCompanyPrompt: 'Invoice to a different Company',
        billToCompanyPlaceholder: 'Search by Company name',
        billToCompanyCreatePrompt: 'Create Company',
        falloffReason: 'Fall Off Reason',
        otherFalloffReason: 'Reason',
        noFalloffReasons: 'No Fall Off reasons loaded',
        falloffNotes: 'Notes to Finance Team',
        isJobOrderOpen: 'Is the Job Order still open?',
        isJobOrderOpenHelper:
          "If No is selected, the Job Order type will be updated to Can't help so recruiters stop looking for candidates to fill this position",
        isCandidateOpen: 'Is the Candidate still looking for opportunities?',
        isCandidateOpenHelper:
          "If No is selected, the Candidate type will be updated to Can't help so recruiters stop looking for a position for this candidate",
        requestedChanges: 'Requested changes',
        suggestedChanged: 'Suggested changes',
        notes: 'Notes'
      },
      sections: {
        create: {
          title: 'Create Placement',
          noHireLetterNotice:
            'Once this Placement is approved, a No Hire Letter will be sent to the Hiring Authority regarding the unplaced Candidates.'
        },
        details: {
          title: 'Placement Details'
        },
        split: {
          title: 'Split',
          companySide: 'Company Recruiter',
          candidateSide: 'Candidate Recruiter',
          recruiter: 'Recruiter',
          email: 'Email',
          percent: 'Split Percent',
          amount: 'Split Amount',
          channelPartner: 'Channel Partner'
        },
        company: {
          title: 'Company',
          name: 'Name',
          address: 'Address',
          phoneWithExt: 'Phone - Ext',
          contactName: 'Contact Person',
          contactEmail: 'Contact Email',
          billToCompany: 'Company to Invoice',
          industry: 'Industry'
        },
        joborder: { title: 'Job Order', position: 'Title', source: 'Source' },
        candidate: { title: 'Candidate', name: 'Name', source: 'Source' },
        estimate: {
          title: 'Estimate',
          description: 'Description',
          descriptionTemplate: 'Executive Search by {recruiter} for the Placement of {candidate}',
          terms: 'Payment Terms',
          showCompensation: 'Show Compensation on Invoice',
          message: 'Message Displayed on Estimate',
          memoNotes: 'Message Displayed on Statement',
          glipMessage: 'Message for Glip',
          accountPayableEmail: 'Account Payabale Email',
          paymentDetails: 'Payment Details',
          additionalRecipients: 'Additional Invoice Recipients',
          notes: 'Notes',
          view: 'View Estimate'
        },
        assignmentDataSheet: {
          title: 'Assignment Data Sheet'
        },
        referenceChecks: {
          title: 'Reference Checks',
          recommendation: `It's recommended to 
          fill at least {total} reference checks. Was a reference registered outside FortPac? 
          Upload the file in the attachments section below.`,
          recommendationForLeads:
            "There's only one Reference Check registered; you can request at least {total} Reference Checks to approve the Placement or approve it as it is.",
          label: 'Toggle Reference Check for {name}',
          optionsFor: 'Options for {candidate}',
          requiredValidation: `Please make sure you've selected or uploaded the reference checks`
        },
        attachments: { title: 'Attachments' }
      },
      ctas: {
        sendPlacement: 'Send Placement',
        updatePlacement: 'Update Placement',
        approvePlacement: 'Approve Placement',
        adjustEstimate: 'Yes, adjust Estimate',
        requestChange: 'Request Change',
        requestAChange: 'Request a Change',
        manageInvoices: 'Manage Invoices',
        saveInvoice: 'Save Invoice',
        viewInvoices: 'View Invoices',
        managePayments: 'Manage Payments',
        savePayment: 'Save Payment',
        viewPayments: 'View Payments',
        viewLogs: 'View Logs',
        requestFallOff: 'Request Fall Off',
        completeFallOff: 'Complete Fall Off',
        switchToPlacement: 'Switch to Placement',
        makeAdjustment: 'Make Adjustment',
        changeEstimate: 'Change Estimate',
        updateEstimate: 'Update Estimate',
        uploadEstimate: 'Upload Estimate',
        approveEstimate: 'Approve Estimate',
        viewEstimate: 'View Estimate',
        requestZipFile: 'Request Zip File',
        sendDownloadLink: 'Yes, send me a download link',
        recreateEstimate: 'Recreate Estimate',
        recreateEstimateGoahead: 'Yes, Recreate the Estimate',
        recreateSalesOrder: 'Create New Sales Order',
        recreateSalesOrderGoahead: 'Yes, Create a New Sales Order',
        deletePlacement: 'Delete Placement'
      },
      confirmations: {
        sendPlacement: 'Are you sure you want to send this Placement for approval?',
        updatePlacement: 'Are you sure you want to update this Placement?',
        approvePlacement: 'Are you sure you want to approve this Placement?',
        approveEstimate: 'Are you sure you want to approve the Estimate?',
        adjustPlacement: 'Are you sure you want to adjust this Placement?',
        adjustEstimate:
          'Once you adjust an Estimate, you must wait for a new one to be created. Are you sure you want to edit this Estimate?',
        requestFallOff: 'Are you sure you want to request to mark this placement as Fall Off?',
        completeFallOff: 'Are you sure you want to mark this placement as Fall Off?',
        requestRevertFallOff:
          'Are you sure you want to request Finance to switch back to Placement?',
        revertFallOff: 'Switching back to placement would perform the following changes:',
        requestZipFile: 'Do you want to create a zip file with all of attachments?',
        recreateEstimate: 'Are you sure you want the Estimate to be recreated?',
        recreateEstimateHelper: `Finance and Recruiter's Approvals will be needed again.`,
        recreateSalesOrder: 'Are you sure you want to create a new Sales Order?',
        recreateSalesOrderHelper:
          'Use this option sparingly because a completely new Sales Order in NetSuite will be created and FortPac Placement ID will change.',
        deletePlacement: 'Are you sure you want to delete this Placement?',
        deletePlacementHelper:
          'You are about to permanently delete this placement and its related information. You will not be able to recover it. <strong>This operation cannot be undone.</strong>'
      },
      messages: {
        title: 'Placement Info',
        creation: 'Are you sure you want to send this Placement for approval?',
        placementUpdated: 'Placement Updated',
        placementUpdatedSubtitle: 'Waiting for approval',
        placementApproved: 'Placement Approved',
        placementApprovedSubtitle: 'The Placement was approved successfully',
        placementAdjusted: 'Placement Adjusted',
        placementAdjustedSubtitle: 'The adjustment was saved successfully',
        placementDeleted: 'Placement Deleted',
        placementDeletedSubtitle: 'The Placement was deleted sucessfully',
        estimateUpdated: 'Estimate Updated',
        estimateUpdatedSubtitle: 'The Estimate will be processed again',
        estimateRecreated: 'Estimate Recreation Requested',
        estimateRecreatedSubtitle: 'The Estimate will be recreated in about 5 minutes',
        salesOrderRecreated: 'Sales Order Recreation Requested',
        salesOrderRecreatedSubtitle: 'The Sales Order will be recreated in about 5 minutes',
        notFound: 'The placement info was not found'
      },
      payments: {
        history: 'Payment history',
        empty: 'No Payments yet'
      }
    },
    feeAgreements: {
      plural: 'Fee Agreements',
      singular: 'Fee Agreement',
      create: 'Create Fee Agreement',
      sections: {
        validations: {
          title: 'Validations',
          empty: 'No validations to Show',
          emptySubtitle: 'All your Fee Agreement validations will appear here'
        },
        sent: {
          title: 'Sent Fee Agreements',
          empty: 'No Fee Agreements to Show',
          emptySubtitle: 'All Fee Agreements sent to sign will appear here'
        },
        signed: {
          title: 'Signed Fee Agreements',
          empty: 'No Signed Fee Agreements to Show',
          emptySubtitle: 'Signed Fee Agreements will appear here'
        }
      },
      fields: {
        feeNo: 'Fee #',
        fee: 'Fee',
        flatAmount: 'Flat Fee Amount',
        guaranteeDays: 'Guarantee Days',
        sentAt: 'Sent at',
        haSignedAt: 'HA Signed at',
        gpacSignedAt: 'gpac Signed at',
        validatedBy: 'Validated by',
        type: 'Type',
        notes: 'Notes from Recruiter',
        ccs: 'CC Emails',
        numberOfPayments: 'Number of Payments',
        payment: 'Payment'
      },
      messages: {
        reminderOk: 'Reminder Sent Successfully',
        approvedOk: 'Fee Agreement Approved Successfully',
        createdOk: 'Fee Agreement Created Successfully',
        submittedOk: 'Fee Agreement Submitted Successfully',
        declinedOk: 'Fee Agreement Declined Successfully',
        savedOk: 'Fee Agreement Saved Successfully',
        voidedOk: 'Fee Agreement Voided Successfully',
        canceledOk: 'Fee Agreement Request Canceled Successfully',
        emailsOk: 'Emails Updated Successfully',
        swithServiceOk: 'Signing Service Changed Successfully',
        reactivatedOk: 'Fee Agreement Reactivated Successfully',
        sentOk: 'Fee Agreement Sent Successfully',
        templateOk: 'Template Created Successfully',
        templateCanceled: 'Signature Request Canceled',
        paymentTermsNotFound: 'Payment Combinations Not Found'
      },
      creation: {
        creationTitle: 'Send Fee Agreement to Sign',
        requestApproval: 'Request approval',
        sendToSign: 'Send to sign',
        sendToOps: 'Send to Ops',
        confirmApproval: 'Are you sure you want to send this Fee Agreement for approval?',
        confirmSendToSign:
          'Are you sure you want to send a signature request to {hiringAuthority}?',
        confirmOpsApproval: 'Are you sure you want to send this Fee Agreement for Ops validation?',
        confirmCreation: 'Are you sure you want to create this agreement?',
        confirmCreationWhenVerbiage:
          'Are you sure you want to create this agreement? Remember to create the template in the Fee Agreements section',
        viewTemplate: 'View contract template',
        chooseProcess: 'Creation state',
        blankState:
          'To create a Fee Agreement signature request, make sure there is a Job Order and the company is ready to sign',
        noHiringAuthorities:
          'Please assign a Hiring Authority to this Company before creating a Fee Agreement',
        blankCTA: 'Create Job Order',
        onBehalf:
          'Please enter a name ONLY if you are sending the agreement on behalf of a recruiter:',
        onBehalfExternal: 'Please select the recruiter who sent this agreement:',
        selectRecruiter: 'Select recruiter',
        feePercent: 'Fee %',
        flatAmount: 'Flat Fee Amount (USD)',
        guaranteeDays: 'Days Under Guarantee',
        verbiageChanges: 'Verbiage Change(s)',
        verbiage: 'Verbiage ',
        requestFeeChange: 'Request Fee Agreement % change',
        requestPaymentTerm: 'Request different Payment Terms',

        requestGuaranteeChange: 'Request Guarantee Period change',
        requestVerbiageChange: 'Request Verbiage changes',
        verbiageValidation: 'Please specify the verbiage changes',
        hiringAuthorityHelper:
          'Are you speaking directly to the decision maker that has the final approval?',
        emailsHelper: `You can add copies to other people involved in the review process - you and your Regional Director get an automatic copy -`,
        emailsPlaceholder: 'Add CCs -up to {0} emails-',
        relatedJobOrders: 'Related Job Orders',
        relatedJobOrdersWithFA: 'With Fee Agreements',
        relatedJobOrdersWithoutFA: 'Without Fee Agreements',
        relatedJobOrdersHelper: 'Make sure your Job Order(s) are linked to this agreement',
        verbiageWhenOnbehalf:
          'After saving this agreement, please go to the Fee Agreements section to create the new template',
        notesFromRecruiter: 'Notes',
        notes: `Please don't use this notes to request verbiage changes. Use the specific section above instead`,
        notesValidation: 'Please specify why you are requesting these changes',
        externalIntro:
          "It's optional to fill out the information below. If you are missing some info, you can request the Ops Team to complete the agreement and upload the file for you.",
        notesToOps: 'Notes to Ops Team',
        signingDate: 'Signing date',
        sentDate: 'Sent date',
        wrongDates: 'Sign date must be after sent date',
        fileExplorerSection: 'Company Files',
        legalNameWarning: 'The legal name is required to prevent possible litigations',
        invalidDecimals: 'Please use only one decimal',
        invalidPercentage: 'Invalid percentage value'
      },
      validation: {
        modifyVerbiage: 'Modify the Following Verbiage Change',
        declinationReason: 'Reasons of declination:',
        modifyDeclined: 'Modify the declined fields',
        opsDeclined: 'Ops Team has explained why the Fee Agreement request was Declined.',
        confirmInformation: 'Confirm information:'
      },
      details: {
        title: 'Fee Agreement Details',
        viewPdf: 'View PDF',
        viewContract: 'View contract template',
        sign: 'Sign',
        void: 'Void',
        confirmVoid: 'Are you sure you want to void this Fee Agreement?',
        validate: 'Validate',
        approve: 'Approve',
        confirmApprove: 'Are you sure you want to approve this Fee Agreement?',
        declineChange: 'Decline changes',
        decline: 'Decline',
        sendReminder: 'Send reminder',
        cancelRequest: 'Cancel request',
        confirmCancel: 'Are you sure you want to cancel this Fee Agreement request?',
        revalidate: 'Re-validate',
        preview: 'Preview & Send',
        copyLink: 'Copy Signing Link',
        updateEmails: 'Update emails',
        changeSigner: "Change signer's email",
        reactivateTitle: 'Reactivate expired agreement',
        reactivateButton: 'Reactivate',
        confirmReactivate: 'Are you sure you want to reactivate this expired agreement?',
        switchServiceTitle: 'Switch to backup signing service',
        switchServiceButton: 'Switch to backup service',
        confirmSwitch:
          'Are you sure you want to switch to the backup signing service for this agreement? Before proceeding, please make sure that:',
        createTemplate: 'Create template',
        requestedChanges: 'Requested changes',
        poweredBy: 'Signature request using ',
        eventHistory: 'Event History',
        lastActivity: 'Last activity',
        titleConfirmSendReminder: 'Confirm Sending Reminder',
        signatureRequestReminded: 'Signature request reminded',
        confirmationSendReminder:
          'Once the reminder is sent, a new link will be created and the previous one will become disabled. Are you sure you want to send a reminder?',
        noReminder: 'No reminder has been sent'
      },
      dashboard: {
        emptyState: {
          title: 'We could not find any information.',
          subtitle: 'It seems like there are no results for what you are looking for.'
        }
      }
    },
    sendouts: {
      title: 'Sendouts',
      interviews: {
        timeRequired: {
          type: 'isBeforeActualDateTime',
          message: `Sendouts can't be scheduled in the past.`
        },
        interviewDetails: {
          actions: {
            cancel: 'Cancel Editing',
            edit: 'Edit Interview'
          }
        }
      },
      attachments: {
        fileSelectorDefault: 'No files attached to this Candidate',
        description: `Candidate files are preloaded. Make sure all files are uploaded to the Candidate's profile before creating a Sendout.`,
        noFilesAdded: 'No attachments added to send',
        noFilesView: 'No attachments added to view',
        noCategories: 'There are no categories to show',
        noFilesSend:
          'These files belong to the Sendout and are not sent to the Hiring Authority. To upload the files correctly, click the update button.',
        removeFiles: `If you don't want to send any Candidate attachments, remove them before sending the Sendout`
      },
      errors: {
        loading:
          'Failed to load this Sendout. Please refresh the page. If the problem persists, contact Fortpac Support.'
      },
      details: {
        navigationPacementConfirm: `You'll be redirected to the Packet Cover Sheet creation form. Do you want to start the Placement for this deal now?`,
        sendoutOnBehalf: `Select the Recruiter you're sending on behalf of`
      },
      hiringAuthority:
        'Changing the Hiring Authority for this Sendout will only be for documentation purposes, but nothing can be sent to them for now.',
      dontSendReminder:
        'By selecting this option, no reminders will be sent to the Candidate nor the Hiring Authority before the interview.',
      nextInterviewDiferent: 'If the next interviewer is different than hiring authority',
      dashboard: {
        emptyState: {
          title: 'We could not find any information.',
          subtitle:
            'It seems like there are no results for what you are looking for. Please try again'
        }
      },
      switchingToSendEmailCopies: {
        isWithoutMailCopies: {
          isWithoutMailTitleCopy:
            'This Sendout {auxiliary} send an email to the Hiring Authority, but you can',
          sendEmailCopy: {
            button: 'Switch and send mail.',
            hint: `It's recommended not to send an email when registering Sendouts created outside FortPac.`
          },
          notSendEmailCopy: {
            button: 'Switch without mail.',
            hint: ` It's recommended to send emails when registering new Sendouts.`
          }
        }
      },
      noEmailSendCopies: {
        title: 'Email not sent to Hiring Authority.',
        button: 'Click here to send it',
        hint: 'Select this option if you want to register a Sendout that was created outside FortPac.'
      },
      duplicates: {
        alert: {
          title: `There's already either a Sendover or a Sendout registered with the same Candidate. To avoid duplicates, convert the Sendover or add new interviews to the Sendout. `
        },
        drawer: {
          title: `Existing Sendovers or Sendouts`,
          subtitle: `Here's the list of Sendovers or Sendouts registered for <b>{jobOrderTitle}</b> with <b>{candidateName}</b>`
        },
        fetch: {
          title: 'Sendovers or Sendouts Already Registered',
          message: 'To avoid duplicates, please look for it to edit its information.'
        }
      },
      tagsAndSignatureCopy: 'Your signature will be included automatically when sending the email.',
      sendEmailToHiringAuthorityCopy: `By clicking on this option, the email won't be sent to anyone, but you'll receive a notification in Fortpac with the Sendout details.`,
      emailTemplateCopy: `You can edit the information on the following box; below it, there's a preview of the email that will be sent.`,
      showConfirmWithEmailCopies: {
        severity: 'warning',
        title: 'Verify Information',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Close',
        message: 'Make sure the information below is correct.'
      },
      showConfirmWithoutEmailCopies: {
        severity: 'warning',
        title: 'Confirm Details',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Close',
        message: 'This info will be sent to everyone involved.'
      },
      showConfirmEditCopies: {
        severity: 'info',
        title: 'Please confirm',
        message: 'Are you sure you want to mark this Sendout as a Placement?'
      },
      showConfirmNoFilesWarningSendoutCopies: {
        severity: 'warning',
        title: 'Send Without Attachments',
        confirmButtonText: 'Send',
        message: `There are no Candidate files attached to this Sendout. Are you sure you want to send it?`
      },
      showConfirmNoFilesWarningSendoverCopies: {
        severity: 'warning',
        title: 'Send Without Attachments',
        confirmButtonText: 'Send',
        message: `There are no Candidate files attached to this Sendover. Are you sure you want to send it?`
      },
      showConfirmDeleteCopies: {
        severity: 'warning',
        title: 'Please confirm',
        message: 'Are you sure you want to delete this Sendout?'
      },
      createdAlert: {
        title: `{type} Created Successfully`
      },
      updatedAlert: {
        title: `{type} Updated Successfully`
      },
      deletedAlert: {
        title: `{type} Deleted Successfully`
      },
      canNotDeletedAlert: {
        title: `{type} can't be deleted`
      },
      showConfirmResendEmail: {
        title: `Resend Email`,
        message: `All attachments included in the {type} will be sent to the Hiring Authority. Are you sure you want to resend this {type}?`,
        success: `{type} was Resent Successfully`
      }
    },
    bulkEmails: {
      title: 'Bulk Email',
      content: {
        drafts: {
          title: 'Bulk Draft Preview',
          menuItemsTitles: {
            edit: 'Edit',
            delete: 'Delete'
          }
        },
        schedule: {
          title: 'Scheduled Bulk Preview',
          menuItemsTitles: {
            edit: 'Edit',
            sendNow: 'Send Now',
            reschedule: 'Reschedule',
            delete: 'Delete'
          }
        },
        sent: {
          searchBarPlaceholder: 'Search by Subject or Search Project',
          actionButton: 'Bulk Stats',
          title: 'Bulk Preview'
        },
        template: {
          searchBarPlaceholder: 'Search by Name',
          title: 'Template Preview',
          menuItemsTitles: {
            edit: 'Edit',
            archive: 'Archive'
          },
          dialogs: {
            discard: {
              title: 'Discard Template',
              message:
                "You'll lose your current progress. Are you sure you want to discard this template?"
            }
          }
        }
      },
      dialogs: {
        cancel: {
          copies: {
            title: 'Discard {titleType} Changes',
            message: `You'll lose your current progress. Are you sure you want to discard this {messageType}?`,
            confirmButtonText: 'Discard',
            cancelButtonText: 'Keep Editing',
            saveButton: '{save}'
          },
          refs: {
            default: {
              titleType: 'Bulk',
              messageType: 'bulk',
              save: 'Save as Draft'
            },
            drafts: {
              titleType: 'Draft',
              messageType: 'draft',
              save: 'Save Changes'
            },
            schedule: {
              titleType: 'Scheduled Bulk',
              messageType: 'bulk',
              save: 'Save Changes'
            }
          }
        },
        restrictions: {
          titles: {
            send: 'Sending too many emails too quickly',
            schedule: 'Scheduling too many bulks in a short time',
            noQuotaAvailable: 'Daily Recipient Quota Reached',
            quotaNearlyReached: 'Quota Nearly Reached',
            defaultRestriction: 'Bulk Unable to Create'
          },
          actions: {
            saveAsDraft: 'Save as Draft',
            close: 'Close',
            cancel: 'Cancel',
            schedule: 'Schedule Bulk',
            setDifferentTime: 'Set a Different Time',
            continueAnyway: 'Continue Anyway'
          },
          bodyMessages: {
            noQuotaAvailable:
              'You have 0 available sends for today. You will be able to send more until tomorrow (00:00 AM CT)',
            quotaNearlyReached:
              'You currently have {remainingQuota} available recipients for today, but your selected Search Project includes {searchProjectSize} recipients. Only the available quota will be sent; You can manage any exceeded emails in your bulk stats.'
          }
        },
        sendBulk: {
          title: `Send Bulk Confirmation`,
          message: `Please confirm all the Bulk's details before sending`,
          confirmButtonText: 'Send',
          cancelButtonText: 'Cancel'
        },
        draftDelete: {
          cancelButtonText: 'Keep',
          confirmButtonText: 'Delete',
          message: 'This action is permanent. Are you sure you want to delete this draft?',
          title: 'Delete Draft'
        },
        scheduleDelete: {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Keep',
          message: 'This bulk will no longer be sent. Are you sure you want to delete this bulk?',
          title: 'Delete Bulk'
        },
        scheduleSendNow: {
          title: 'Send Scheduled Bulk',
          message: `Are you sure you want to send this bulk now?`,
          confirmButtonText: 'Send',
          cancelButtonText: 'Cancel'
        },
        scheduleForm: {
          timezoneInfo:
            'The bulk will be scheduled in the timezone of your location<br>(Current Timezone: {currentTimeZone})',
          create: {
            body: 'Pick date & time to schedule',
            copies: {
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Schedule',
              title: 'Schedule Bulk'
            }
          },
          edit: {
            body: 'Pick date & time to reschedule',
            copies: {
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Reschedule',
              title: 'Reschedule Bulk'
            }
          }
        },
        archiveTemplates: {
          title: 'Archive {entity}',
          message:
            'The {entity} will be available for {retentionDays} days in the Archived Folder. If not restored before those days, it will be permanently deleted. Are you sure you want to archive this {entity}?',
          confirmButtonText: 'Archive {entity}',
          cancelButtonText: 'Cancel'
        },
        restoreTemplates: {
          copies: {
            title: 'Restore {entity}',
            message:
              'The {entity} will be back in your folders. Are you sure you want to restore this {entity}?',
            confirmButtonText: 'Restore {entity}',
            cancelButtonText: 'Cancel'
          },
          detailsTitles: {
            directoryFolder: 'Directory Folder'
          }
        }
      },
      emptyStates: {
        emailItems: {
          searchProjectName: '(No Search Project Selected)',
          subject: '(No Subject)'
        },
        sent: {
          list: {
            title: 'No Bulks Sent',
            subtitle: 'All your sent bulks will appear here.'
          },
          preview: {
            title: 'No Bulk Selected',
            subtitle: 'Select a bulk to preview.'
          }
        },
        drafts: {
          list: {
            title: 'No Drafts to Show',
            subtitle: 'All your bulk drafts will appear here.'
          },
          preview: {
            title: 'No Draft Selected',
            subtitle: 'Select a draft to preview.'
          }
        },
        template: {
          list: {
            title: 'No Templates to Show',
            subtitle: 'All your bulk Templates will appear here.'
          },
          preview: {
            title: 'No Template Selected',
            subtitle: 'Select a template to preview.'
          }
        },
        schedule: {
          list: {
            title: 'No Scheduled Bulks to Show',
            subtitle: 'All your scheduled bulks will appear here.'
          },
          preview: {
            title: 'No Scheduled Bulk Selected',
            subtitle: 'Select a scheduled bulk to preview.'
          }
        }
      },
      errors: {
        archiveBulkTemplate: {
          title: 'Template archived successfully'
        },
        restoreBulkTemplate: {
          title: 'Template Restored Successfully',
          body: '"{templateName}" restored in {destinationFolderName}'
        },
        archiveBulkTemplateFolder: {
          title: 'Folder archived successfully'
        },
        restoreBulkTemplateFolder: {
          title: 'Folder Restored Successfully',
          body: '"{folderName}" restored in {destinationFolderName}'
        },
        removeAttachments: {
          noType: {
            title: 'File Missing Type',
            body: `The file you're trying to remove doesn't have type. Allowed types (temporal, template)`
          }
        },
        gettingSentEmails: {
          not200: {
            title: 'Error while getting sent emails',
            body: `Couldn't retrieve sent emails`
          },
          unhandled: {
            title: 'Error while getting sent emails'
          }
        },
        gettingDrafts: {
          not200: {
            title: 'Error while getting drafts',
            body: `Couldn't retrieve drafts`
          },
          unhandled: {
            title: 'Error while getting drafts'
          }
        },
        loadBulkPreview: {
          title: 'Error setting preview data'
        },
        updateBulkEmailDraft: {
          title: "Couldn't update draft"
        },
        deleteBulkEmailDraft: {
          title: "Couldn't delete draft"
        },
        gettingSchedules: {
          not200: {
            title: 'Error while getting schedule emails',
            body: `Couldn't retrieve schedule emails`
          },
          unhandled: {
            title: 'Error while getting schedule emails'
          }
        },
        updateScheduleDate: {
          title: `Unexpected Error while updating scheduled bulk`
        },
        deleteScheduledBulkEmail: {
          title: 'Unexpected error while trying to discard scheduled bulk'
        }
      },
      forms: {
        fields: {
          searchProject: {
            label: 'To: *',
            placeholder: 'Enter Search Project Name',
            noValue: 'No Search Project Selected'
          },
          bulkType: {
            hint: "Bulk Types identify the people you're sending this bulk to. Select a type for this bulk.",
            label: 'Bulk Type: *',
            placeholder: 'Select Bulk Type',
            noValue: 'No Bulk Type Selected',
            typeAndDescription: {
              Marketing: 'Marketing (Send only to Hiring Authorities)',
              Recruiting: 'Recruitment (Send only to Candidates)',
              General: 'General (Send to Everyone)'
            }
          },
          candidatesToMarket: {
            hint: `Select the candidate(s) you want to market. The bulk won't be sent to the Hiring Authorities that are currently in the same company as a selected Candidate.`,
            label: 'Candidates to Market: *',
            placeholder: 'Select Candidate(s) to market',
            noValue: 'No Candidates to Market Added'
          },
          generalBulkCategory: {
            label: 'General Category: *',
            placeholder: 'Select General Bulk category',
            noValue: 'No General Category Selected'
          },
          jobOrdersToRecruit: {
            label: 'Job Orders to Recruit: *',
            placeholder: 'Select Job Order(s) to recruit',
            noValue: 'No Job Orders to Recruit Added',
            hint: 'Select the job order(s) you want to recruit. Adding job orders to this bulk will update their activity log.'
          },
          template: {
            label: 'Template (optional):',
            placeholder: 'Select Template',
            noValue: 'No Template Selected'
          },
          blockResend: {
            adviceTexts: {
              disabled: 'To enable this option, you must select a template first.',
              off: 'The template will be sent to contacts who may have already received it.',
              on: "The template won't be sent to contacts who have already received it within the selected time interval."
            },
            label: 'Block Resend:',
            hint: 'This option will block the contacts who have received the selected template before. Turn on Block Resend to block contacts within a time interval.'
          },
          resendTime: {
            label: 'Within the Last:',
            placeholder: 'Select Time Interval'
          },
          subject: {
            label: 'Subject: *',
            placeholder: 'Enter Subject',
            noValue: 'No Subject Added'
          },
          attachFiles: {
            label: 'Attach Files:'
          },
          smartTags: {
            label: 'Insert Smart Tag:',
            placeholder: 'Select Smart Tag',
            hint: 'Use fields to personalize your bulk email. Select one to insert it.'
          }
        },
        validations: {
          searchProject: {
            required: 'Search project recipient is required'
          },
          bulkType: {
            required: 'Bulk type is required'
          },
          generalBulkCategory: {
            required: 'Category is required'
          },
          subject: {
            required: 'Subject is required'
          },
          emailBody: {
            required: 'Bulk Email body is required'
          },
          templateEmailBody: {
            required: 'A Body is required to create this template'
          },
          resendTime: {
            required: 'Time Interval is required'
          },
          marketCandidates: {
            required: 'Candidate(s) to market is required'
          },
          jobOrdersToRecruit: {
            required: 'Job Order is required'
          },
          scheduleDate: {
            required: 'Schedule Date is required'
          },
          scheduleTime: {
            required: 'Schedule Time is required'
          },
          templateParentFolder: {
            required: 'Directory folder is required to organize your template'
          },
          templateName: {
            required: 'Name is required'
          }
        },
        smartTags: {
          validations: {
            wellWritten:
              'The {field} contains incorrect smart tags. Make sure all smart tags are well-written',
            unsupportedSmarTag:
              'The {field} contains an invalid smart tag. To send the bulk, the following smart tag must be removed: {smartTag}',
            atLeastOne: 'At least one smart tag (recipient) is required'
          }
        },
        marketingOptions: {
          blockCompanies:
            "Block Hiring Authorities from the same Candidate's Company in all its locations",
          blockSigned: 'Block all Signed or Client Companies in this Search Project',
          allowMarketingCandidates: 'Allow the Bulk to reach Candidates in this Search Project'
        },
        recruitingOptions: {
          allowRecruitHirings: 'Allow the bulk to reach Hiring Authorities in this Search Project'
        },
        sendThroughOutlook: {
          alertAction: 'Learn More',
          checkboxLabel: 'Send bulk through Outlook.',
          warning:
            "Max {initialQuota} daily emails via Outlook ({remainingQuota}/{initialQuota} available until 11:59 PM CST); exceeding will go to Exceeded Quota section in the bulk stats, where you'll be able to manage them. To learn more, click the button."
        },
        bulkGeneralCategoryWarning:
          'Do not use General Bulks to promote inventory, for that please use Marketing or Recruitment types',
        bulkQuotaWarning:
          'Bulking Available quota {remainingQuota}/{initialQuota} recipients (resetting at 23:59 CT). We are transitioning to our own Bulking Service; during this period FortPac will send your bulks selecting the best available service.',
        emailVariants: {
          action: 'Create Email Variant',
          body: "To improve your bulk's deliverability and prevent being flagged as spam, it is ESSENTIAL for you to split your Search Projects into smaller groups and use variants of your email subject and body. You can use our email variant generator."
        },
        signatureInfo: {
          selectOption: {
            value: '{{your_signature}}',
            name: 'Email Signature (Automatically included when sending the bulk)',
            type: 'sender'
          },
          helperText:
            'The signature will be automatically included and visible upon sending the bulk, as well as in the bulk preview.'
        },
        errors: {
          customSmartTagsValidations:
            'At least one required option property is missing on bulkEmailCutomValidations options param'
        }
      },
      layout: {
        userFiltersPlaceholder: 'Bulks to Show',
        menuItemsTitles: {
          sent: 'Sent',
          template: 'Templates',
          drafts: 'Drafts',
          schedule: 'Scheduled Bulks',
          optout: 'Opt in / Opt out'
        }
      },
      preview: {
        mailMeta: {
          attachments: {
            title: 'Attachments'
          },
          blockResend: {
            title: 'Block Resend'
          },
          booleanOptions: {
            yes: 'Yes',
            no: 'No'
          },
          bulktTypeEntities: {
            tooltip: 'Go to {entity} Profile',
            entities: {
              candidate: "Candidate's",
              joborder: "Job Order's"
            }
          },
          subject: {
            defaultValue: '(No Subject)'
          },
          from: {
            title: 'From'
          },
          to: {
            title: 'To',
            renderValueTitle: 'Go to Search Project Preview',
            defaultValue: '(No Search Project selected)'
          },
          bulkType: {
            title: 'Bulk Type',
            defaultValue: '(No Bulk Type selected)'
          },
          generalCategory: {
            title: 'General Category',
            defaultValue: '(No General Category selected)'
          },
          isSchedule: {
            title: 'Bulk Scheduled'
          },
          candidatesToMarket: {
            title: 'Candidates to Market',
            defaultValue: '(No Candidates to market selected)',
            config: {
              blockHirings: 'Block HAs from same company and location',
              blockSignedClientCompanies: 'Block signed and client companies',
              allowReachingCandidates: 'Allow Candidate reach'
            }
          },
          jobOrdersToRecruit: {
            title: 'Job Orders to recruit',
            defaultValue: '(No Job Orders to recruit selected)',
            config: {
              allowReachingHirings: 'Allow Hiring Authority reach'
            }
          },
          template: {
            title: 'Template'
          },
          date: {
            title: 'Date'
          },
          emailBody: {
            defaultValue: '(No content to display)'
          },
          showButton: {
            text: 'Show {moreLess}'
          }
        }
      },
      schedule: {
        bulkFormAlert: {
          scheduledFailed: `This bulk's scheduling has failed. Your action is required to reschedule.`,
          remainingTime: `{quantity} {unitOfTime} remaining. This bulk will be sent on {formattedDate}`
        },
        errors: {
          beforeActualDateTime: `Bulks can't be scheduled in the past. Please set a schedule time that hasn't occurred.`,
          emailCouldntBeSent: 'Send Failure. Open bulk to reschedule or send.'
        }
      },
      stats: {
        title: 'Bulk Stats Management',
        details: {
          title: 'Bulk Details',
          sections: {
            from: 'From',
            searchProject: 'Search Project',
            bulkType: 'Bulk Type',
            date: 'Date'
          }
        },
        alerts: {
          info: {
            resume: {
              description: `Here are the latest stats of the bulk you sent {formattedDate}. These may change
              because they're updated on time. To learn about email dropping due to frequency rates, click the <b>Learn More</b> button`
            }
          }
        },
        cards: {
          viewDetails: 'View Details',
          pending_emails: {
            description: 'Emails waiting to be sent.',
            title: 'Queued'
          },
          quota_exceeded_emails: {
            description:
              'Emails not sent because quota was exceeded. You can create a search project and schedule a new bulk for later.',
            title: 'Exceeded Quota'
          },
          sent_emails: {
            description:
              'Recipients that received the bulk. This number updates every time the bulk reaches a new email address.',
            title: 'Delivered Emails'
          },
          open_emails: {
            description: 'Delivered emails that have been opened by the recipients.',
            title: 'Open'
          },
          spam_emails: {
            description: `Delivered emails that recipients marked as spam or unsubscribed upon receiving it.`,
            title: 'Exclusion Reports'
          },
          click_emails: {
            description: `Delivered emails that recipients clicked the bulk’s link (One click per recipient).`,
            title: 'Links Clicked-through'
          },
          bounced_emails: {
            description: `Emails bounced due to the recipient’s policies or filtering systems. Click to view the reason for each recipient.`,
            title: 'Bounced'
          },
          unreachable_emails: {
            description:
              'Recipients that can’t receive our emails due to no longer existing or the recipient blocked our IP addresses/email domain. Click to view the reason for each recipient.',
            title: 'Unreachable'
          },
          dropped_emails: {
            description: `Emails not sent due to business rules (e.g., Recruiting a Client, Block Resend). Click to view the reason for each recipient.`,
            title: 'Dropped'
          },
          fixable_emails: {
            description: `Recipients that didn’t receive the bulk due to an invalid or missing email address. Click to view the reason for each recipient.`,
            title: 'Fixable'
          },
          unsubscribe_emails: {
            description: `Recipients that were already unsubscribed before sending the bulk. Click to view the reason for each recipient.`,
            title: 'Unsubscribed'
          },
          original_meant_recipients: {
            description:
              'Full Stats = Delivered + Bounced + Unreachable + Dropped + Fixable + Unsubscribed',
            title: 'Full Stats Overview'
          },
          reply_emails: {
            description: 'Recipients who responded to the email.',
            title: 'Replied'
          }
        },
        resume: {
          openRate: {
            title: 'Open Rate',
            description:
              'The percentage of sent emails that were opened by the recipients (Only one opening per recipient).'
          }
        }
      },
      success: {
        removeAttachments: {
          template: {
            title: 'File Removed',
            body: 'The file was removed successfully'
          }
        },
        updateBulkEmailDraft: {
          title: 'Bulk Draft Updated Successfully'
        },
        deleteBulkEmailDraft: {
          title: 'Draft Deleted Successfully'
        },
        scheduleBulkEmail: {
          title: 'Bulk Scheduled Successfully',
          body: 'Sending on {formattedDate}'
        },
        updateScheduledBulkEmail: {
          title: 'Scheduled Email Updated Successfully'
        },
        sendScheduledBulkNow: {
          title: 'Bulk Scheduled Successfully',
          body: `Sent on {sendDate}`
        },
        deleteScheduledBulkEmail: {
          title: 'Scheduled Bulk Deleted Successfully'
        }
      },
      templateActions: {
        actions: {
          newTemplate: 'New Template',
          newFolder: 'New Folder'
        },
        tooltipText:
          'You can only create new folders and templates of your own. Please go to "My Folders" to enable the options.'
      },
      utils: {
        alert: {
          error: {
            getMappedBulkData: {
              body: 'Error mapping bulk email data',
              title: 'Missing data'
            },
            updateByBulkType: {
              body: `Bulk type doesn't match with any update method`,
              title: 'Unable to update bulk'
            }
          }
        }
      }
    },
    searchProjects: {
      dialogs: {
        archiveFolder: {
          title: 'Archive Folder',
          message:
            'The folder will be stored in the Archived Folder for {days} days. If not restored within this period, both the folder and its contents will be permanently deleted. Are you sure you want to archive this folder?',
          confirmButtonText: 'Archive Folder',
          cancelButtonText: 'Cancel'
        }
      },
      mainPage: {
        searchBox: {
          placeholder: 'Search by folder or name',
          groupsTitles: {
            mine: 'MY SEARCH PROJECTS',
            other: "OTHER RECRUITER'S PUBLIC SEARCH PROJECTS"
          }
        },
        emptyStates: {
          noFolders: {
            title: "Couldn't Retrieve Information",
            subtitle: 'Please refresh the page. If the problem persist, contact FortPac Support.'
          }
        },
        filtersTitles: {
          mine: 'My Search Projects',
          archived: 'Archived'
        },
        layoutContainer: {
          foldersList: {
            actions: {
              newFolder: 'New Folder'
            }
          },
          header: {
            menuItemsTitles: {
              edit: 'Edit',
              archive: 'Archive',
              restore: 'Restore'
            }
          }
        }
      },
      services: {
        alerts: {
          success: {
            generalTitle: 'Folder {action} Successfully',
            actions: {
              archive: 'Archived',
              create: 'Created',
              edit: 'Edited',
              restore: 'Restored'
            }
          },
          error: {
            general: {
              title: 'Error While {action} Folder',
              body: 'Contact FortPac Support'
            },
            actions: {
              archive: 'Archiving',
              create: 'Creating',
              edit: 'Editing',
              restore: 'Restoring'
            }
          }
        }
      },
      forms: {
        searchProject: {
          titles: {
            create: 'New Search Project',
            edit: 'Edit Search Project',
            extract: 'Extract Contacts to New Search Project'
          },
          sections: {
            titles: {
              details: 'Search Project details',
              parentFolder: "Select Search Project's location"
            },
            name: {
              label: 'Search Project Name *',
              requiredMessage: 'Search Project name is required',
              minLengthMessage: 'Search Project name must have at least 5 characters'
            },
            visibility: {
              private: '* Only you can access to this Search Project',
              public: '* Everyone can access this Search Project'
            },
            parentFolder: {
              placeholder: 'Parent Folder*',
              requiredMessage: 'Parent Folder is required'
            }
          },
          extractedSufix: 'Extracted Contacts',
          alerts: {
            success: {
              title: 'Search Project Updated Successfully'
            },
            error: {
              title: 'Error while updating Search Project'
            }
          }
        },
        restoreFolder: {
          title: 'Restore Folder',
          sections: {
            parentFolder: {
              title: "Select the folder's location",
              placeholder: 'Parent Folder'
            }
          }
        }
      },
      folderForm: {
        title: {
          generalTitle: '{action} Folder',
          actions: {
            create: 'Create',
            edit: 'Edit'
          }
        },
        sections: {
          folderName: {
            title: "Enter folder's name",
            placeholder: 'Folder Name *'
          },
          parentFolder: {
            title: "Select folder's location",
            placeholder: 'Parent Folder *'
          }
        },
        validations: {
          required: {
            folderName: "Folder's name is required",
            parentFolder: 'Folder location is required'
          }
        }
      },
      archiveDialog: {
        dialog: {
          title: 'Archive Search Project',
          message:
            'The Search Project <b>{searchProjectName}</b> will be available for <b>{availabilityDays} days</b> in the Archived list before <b>automatic deletion</b>.<br><br>Any bulk in process or scheduled for this Search Project <b>will be sent</b>.<br><br>Are you sure you want to archive this Search Project?',
          confirmButtonText: 'Archive',
          cancelButtonText: 'Cancel'
        },
        deleteDialog: {
          title: 'Delete Search Project',
          message: `This Search Project will no longer appear or receive bulks. The Contacts won't be deleted from FortPac. Are you sure you want to delete this Search Project?`,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Keep'
        },
        alerts: {
          success: {
            title: 'Search Project {action} Successfully'
          },
          error: {
            title: 'Error while {action} Search Project'
          }
        }
      },
      restoreDialog: {
        alerts: {
          success: {
            title: 'Search Project Restored Successfully'
          },
          error: {
            title: 'Error while Restoring Search Project'
          }
        },
        main: {
          title: 'Restore Search Project',
          message:
            'The Search Project will be back in your Search Projects list. If not restored before {availabilityDays} days since archived, it will be permanently deleted. Do you want to restore this Search Project?',
          confirmButtonText: 'Restore',
          cancelButtonText: 'Cancel'
        },
        detailsTitles: {
          name: 'Search Project Name',
          archivedDate: 'Archived Date'
        }
      },
      addForm: {
        title: 'Add to my Search Projects'
      },
      options: {
        description: 'Select the items you want to add in the Search Project:',
        addEmployees: "Add Company's Employees",
        addHiringAuthorities: "Add Company's Hiring Authorities"
      },
      activityLog: {
        drawer: {
          title: 'Search Project History Log'
        }
      },
      collabsForm: {
        alerts: {
          collabsRemoved: {
            title: 'Collaborators Removed Successfully',
            // i.e. - 3 collaborators removed from demo search project
            body: `{collabsLength} {noun} removed from {searchProjectName}`
          },
          userAlreadySelected: {
            title: 'Collaborator Already Added',
            body: '{name} is already in this Search Project. Select a different collaborator.'
          }
        },
        dialogs: {
          saveCollabsChanges: {
            dialog: {
              title: 'Remove Collaborators',
              message:
                'The {person} will no longer have permission to edit or send bulks to this Search Project. Are you sure you want to remove {determiner} {person}?',
              confirmButtonText: 'Remove',
              cancelButtonText: 'Keep'
            },
            singular: {
              person: 'collaborator',
              determiner: 'this'
            },
            plural: {
              person: 'collaborators',
              determiner: 'these'
            }
          },
          discardCollabsChanges: {
            title: 'Discard Changes',
            message:
              'If you close the window without saving, the changes in this Search Project will be lost. Are you sure you want to discard these changes?',
            confirmButtonText: 'Save Changes',
            cancelButtonText: 'Discard Changes'
          }
        }
      },
      splits: {
        form: {
          title: 'Split Search Project',
          splitOptions: {
            title: '{0} Search Projects',
            quantity: {
              two: '2',
              three: '3',
              more: 'More'
            }
          },
          splitInfoTitles: {
            name: 'Search Project Name',
            totalItems: 'Total Items',
            visibility: 'Visibility'
          },
          validations: {
            splitOptions: {
              required: 'The number of splits is required',
              validate: `The number of splits can't be greater than the number of items in your current Search Project`
            },
            searchProjectName: {
              required: 'The search project name is required'
            },
            splitIntoParts: {
              required: 'The number of splits is required',
              max: '$ref{searchProjects.splits.form.validations.splitIntoParts.numberOfSplits.max} splits at a time is the maximum number allowed for each Search Project',
              min: 'At least $ref{searchProjects.splits.form.validations.splitIntoParts.numberOfSplits.min} splits are required to split a Search Project',
              validate: {
                integer:
                  'Enter a number between $ref{searchProjects.splits.form.validations.splitIntoParts.numberOfSplits.min} and $ref{searchProjects.splits.form.validations.splitIntoParts.numberOfSplits.max} (no decimals)',
                greaterThanCurrentItems: `The number of splits can't be greater than the number of items in your current Search Project`
              },
              numberOfSplits: {
                max: process.env.REACT_APP_SEARCH_PROJECT_MAX_SPLIT,
                min: 2
              }
            }
          },
          confirmDialog: {
            title: 'Split Search Project',
            message: `You're about to split <b>{searchProjectName}</b> into {parts} new Search Projects. Are you sure you want to split this Search Project?`,
            confirmButtonText: 'Split',
            cancelButtonText: 'Cancel'
          },
          sections: {
            resume: {
              title: 'Information'
            },
            partsSelection: {
              title: 'Split Into*'
            },
            preview: {
              title: 'Split Search Projects Information',
              subtitle: `You can edit the Search Project's name if needed. To differentiate them, the split number will be added automatically.`,
              resume: {
                name: '{searchProjectName} | Part (Nº) | {date}',
                totalItems: '{totalItems} in each Search Project (approx.)'
              }
            }
          }
        },
        services: {
          success: {
            splitSearchProject: {
              title: 'Search Project Split Successfully',
              body: '{partsQuantity} new Search Projects'
            }
          },
          error: {
            splitSearchProject: {
              title: 'Unknown Error While Splitting Search Project'
            }
          }
        }
      },
      menuItems: {
        add: {
          title: 'Add to Search Project'
        },
        create: {
          title: 'Create new Search Project'
        },
        remove: {
          title: 'Remove From this Search Project'
        },
        copy: {
          toExisting: {
            title: 'Copy to an Existing Search Project'
          },
          toNew: {
            title: 'Copy to a New Search Project'
          }
        },
        move: {
          toExisting: {
            title: 'Move to an Existing Search Project'
          },
          toNew: {
            title: 'Move to a New Search Project'
          }
        },
        map: {
          add: {
            title: 'Existing Search Project'
          },
          create: {
            title: 'New Search Project'
          }
        }
      },
      preview: {
        actionTitles: {
          archivedSearchProject: 'Restore',
          moreOptions: 'More Options'
        },
        buttons: {
          removeItems: 'Remove from Search Project',
          sendBulk: 'Send Bulk',
          sendBulkDisabled: `No items to bulk (Companies can't receive bulks).`
        },
        dialogs: {
          removeItems: {
            title: 'Remove Selected Items',
            message:
              'These items will no longer appear on the Search Project "{spName}". Are you sure you want to remove the selected items?',
            confirmButtonText: 'Remove',
            cancelButtonText: 'Keep'
          }
        },
        extraRenderers: {
          NAChip: {
            hint: 'No Company is linked to this Candidate. Add a Company to start marketing it.',
            label: 'N/A',
            variant: 'danger'
          }
        }
      },
      profiles: {
        dialogs: {
          removeProfile: {
            title: 'Remove from Search Project',
            message:
              'This {entity} will no longer appear on the Search Project "{spName}". Are you sure you want to remove {itemName} from this Search Project?',
            confirmButtonText: 'Remove',
            cancelButtonText: 'Keep'
          }
        },
        tabs: {
          addButtonText: 'Add to Search Project',
          emptyPlaceholderTitle: 'No Search Projects to Show',
          emptyPlaceholderSubtitle:
            'To add this {entity} to a Search Project, click the “Add to Search Project” button.'
        }
      }
    },
    bulkEmailDashboard: {
      title: 'Bulk Emails',
      section: {
        name: 'dashboardBulkEmail',
        titles: {
          totalSent: 'Total Bulks Sent',
          trends: 'Sent Bulk Trends',
          ratio: 'Open Ratio',
          totalStats: 'Total Bulk Stats',
          withActivity: 'Bulk Overview'
        }
      }
    },
    phoneDashboard: {
      title: 'Calls & Texts',
      sections: {
        totalCalls: {
          title: 'Total Calls'
        },
        callTrends: {
          title: 'Call Trends'
        },
        calls: {
          title: 'Calls'
        },
        totalTexts: {
          title: 'Total Texts'
        },
        textTrends: {
          title: 'Text Trends'
        },
        texts: {
          title: 'Texts'
        },
        noPhoneActivity: {
          noNewCalls: 'No New Calls',
          noNewTexts: 'No New Texts',
          title: 'No phone Activity'
        }
      }
    },
    map: {
      inventory: {
        locationHint:
          "You're now viewing results based on the visible area of the map. The location filters are no longer applied.",
        alertTitle: 'Not enough filters applied',
        alertBody: 'Apply at least an Industry, Team or Location filter to show results',
        popover: {
          industrySpecialityLabel: 'INDUSTRY · SPECIALTIES',
          recruiterLabel: 'RECRUITER'
        }
      },
      dig: {
        noResults: {
          title: "We couldn't find what you're looking for!",
          subtitle: 'Maybe try changing your search filters'
        },
        alertBody: 'Select at least one filter'
      }
    },
    candidates: {
      editDataSheet: {
        contentPageTitle: 'Edit Candidate Data Sheet',
        title: 'Candidate Data Sheet',
        subheader: 'Fields marked with * are required',
        uiMessages: {
          warning: 'Please fill in the required fields',
          error: 'Some fields have inconsistencies or are missing information',
          skillsError:
            'Please enter a valid value. Avoid using special characters; use commas to divide skills',
          complete: {
            success: 'Data Sheet Saved Successfully',
            error: 'There was an error saving the Data Sheet'
          },
          draft: {
            success: 'Data Sheet Progress Saved Successfully',
            error: 'There was an error saving the Data Sheet Progress'
          }
        }
      },
      create: {
        candidateDataSheet: {
          new: {
            title: 'Candidate Data Sheet',
            subtitle:
              'It is required to fill the Candidate Data sheet and choose the Candidate type in order to add it.'
          },
          old: {
            title: 'Write Up',
            subtitle:
              'It is required to fill the write up and choose the candidate type in order to add it *'
          }
        }
      },
      creation: {
        title: 'New Candidate',
        titleFromDirectory: 'New Candidate from Directory',
        mainSection: 'Personal Data',
        enterEmail: {
          section: 'To create a new Candidate, please enter the email:',
          placeholder: 'Enter a valid email *',
          confirm: {
            title: 'Edit Candidate Email',
            message:
              "By editing the email of this new Candidate, you'll lose your current progress. Edit it anyway?",
            confirmButtonText: 'Edit Email'
          }
        },
        selectExisting: {
          message: 'You are creating a new Candidate, but you can',
          link: 'select an existing Contact or Hiring Authority',
          continueMessage: 'instead.'
        },
        selectExistingFromDirectory: {
          message: 'Choose a Contact or a Hiring Authority to add as a Candidate or',
          link: 'Create a new one.',
          placeholder: 'Select a Contact or a Hiring Authority'
        },
        hiringAuthorities: {
          title: 'Hiring Authorities',
          helper:
            "You can add the Hiring Authority later, but remember that it'll be needed to create a Job Order",
          add: 'Add Hiring Authority'
        },
        similarEmail: {
          alert:
            'The email {email} is already registered in FortPac. To proceed with this email, please click the button, or try a different email',
          alertButton: ' Continue Using Email',
          alertContinue: ' or try another email.'
        },
        duplicateEmail: {
          alert:
            "The email {email} is already in FortPac {entity} and won't be saved to avoid duplicates. If this is an error, please contact FortPac Support "
        },
        assignRecruiter: {
          title: 'Assign to a recruiter',
          subtitle: 'Choose a recruiter to assign this item to.',
          placeholder: 'Choose a Recruiter'
        },
        success: {
          title: 'Candidates',
          body: 'Awesome! The Candidate was created successfully'
        },
        candidateWithoutEmail: {
          title: 'Contact with No Email',
          body: 'There is no valid email in the selection'
        }
      },
      profile: {
        dataSheet: {
          hint: 'To create a Placement, the Candidate Data Sheet must be filled. The completed Candidate Data Sheet will be used for future job posting and bulking integration.',
          title: 'Candidate Data Sheet',
          editButton: {
            personalData: 'Edit Personal Data',
            old: 'Edit Write Up'
          }
        }
      }
    },
    contacts: {
      creation: {
        title: 'New Contact',
        form: {
          title: 'Personal Data',
          subheader: 'Fields marked with * are required to add this lead'
        },
        alert: {
          title: 'Contacts',
          success: {
            body: 'Awesome! The contact was created successfully'
          }
        }
      }
    },
    drafts: {
      confirmDialog: {
        save: {
          title: 'Save Draft',
          message:
            'Are you sure you want to save this {entity} as a draft? You can continue editing later.',
          confirmButtonText: 'Save Draft',
          cancelButtonText: 'Cancel'
        },
        saveOnCancel: {
          title: 'Save Draft',
          message: 'Do you want to save this {entity} as a draft? You can continue editing later.',
          confirmButtonText: 'Save Draft',
          cancelButtonText: 'Discard'
        },
        delete: {
          title: 'Delete Draft',
          message:
            'All the information registered for this {entity} will be lost. Are you sure you want to delete this draft?.',
          confirmButtonText: 'Delete Draft',
          cancelButtonText: 'Cancel'
        },
        deleteAll: {
          title: 'Delete All Drafts',
          message:
            'All the information registered for these {entity} will be lost. Are you sure you want to delete all drafts??',
          confirmButtonText: 'Delete All',
          cancelButtonText: 'Cancel'
        }
      },
      alert: {
        success: {
          save: {
            title: 'Draft saved successfully',
            body: 'You can come back to edit it later'
          },
          delete: {
            title: 'Draft deleted successfully!'
          }
        }
      },
      cardTitle: 'My Drafts',
      deleteAll: 'Delete All',
      button: {
        update: 'Update Draft',
        save: 'Save as Draft'
      }
    },
    inventoryProfiles: {
      emptyStates: {
        fallBack: {
          title: 'No Results to Show',
          subtitle: `We couldn't find any information to what you’re looking for. Please try another search`
        },
        activityLogs: {
          quickView: {
            title: "Nothing done yet! Let's register your activity",
            subtitle: 'Once an Activity is added, it will be shown here'
          },
          profile: {
            title: "Nothing done yet! Let's register your activity",
            subtitle: 'Too add one, click the + Button'
          }
        },
        candidates: {
          quickView: {
            title: 'No Candidates to Show',
            subtitle: 'Once a Candidate is added, it will be shown here'
          },
          profile: {
            title: 'No Candidates to Show',
            subtitle:
              'To match this Job Order with a Candidate, click the “+ Match Candidate” button'
          }
        },
        companies: {
          quickView: {
            title: 'No linked Companies',
            subtitle: 'Once a Company is added, it will be shown here'
          },
          profile: {
            title: 'No linked Companies',
            subtitle: 'To link a Company, click on the button'
          }
        },
        feeAgreements: {
          quickView: {
            title: 'No Fee Agreements to Show',
            subtitle: 'Once a Fee Agreements is added, it will be shown here'
          },
          profiles: {
            title: 'No Fee Agreements to Show',
            subtitle: 'To create a Fee Agreement, click the “+ New Fee Agreement” button'
          }
        },
        files: {
          title: 'No Files to Show',
          subtitle: 'To add an attachment, click the “Upload File” button'
        },
        hiringAuthorities: {
          quickView: {
            title: 'No Hiring Authorities to Show',
            subtitle: 'Once a Hiring Authority is assigned, it will be shown here'
          },
          profile: {
            title: 'No Hiring Authorities to Show',
            subtitle: 'To assign a Hiring Authority, click the “+ New Job Order” button.'
          }
        },
        joborders: {
          quickView: {
            title: 'No Job Orders to Show',
            subtitle: 'Once a Job Order is added, it will be shown here.'
          },
          profile: {
            title: 'No Job Orders to Show',
            subtitle: 'To add a Job Order, click the “+ New Job Order” button.'
          }
        },
        referenceCheck: {
          title: 'No References to Show',
          subtitle: 'To add a Reference, click the “+ New Reference” button.'
        },
        notes: {
          quickView: {
            title: 'No Notes to Show',
            subtitle: 'Once a Note is added, it will be shown here'
          },
          profile: {
            title: 'No Notes to Show',
            subtitle: 'To leave a note, click the “+ New Note” button'
          }
        },
        placements: {
          quickView: {
            title: 'No Placements to Show',
            subtitle: 'Once a Placement is created, it will be shown here. '
          },
          profiles: {
            title: 'No Placements to Show',
            subtitle: 'Once a Placement is created, it will be shown here'
          }
        },
        sendouts: {
          quickView: {
            title: 'No Sendouts to Show',
            subtitle: 'Once a Sendout is added, it will be shown here.'
          },
          profiles: {
            title: 'No Sendouts to Show',
            subtitle: 'To Create a Sendout the Job Order needs to be assigned to you'
          }
        }
      },
      common: {
        editProfile: 'Edit Information',
        candidateAddition: 'Add as Candidate',
        haAddition: 'Add as Hiring Authority',
        optOut: {
          isOptOut: 'Opt out',
          creation: 'Opt out since'
        },
        emailValidationStatus: {
          valid: 'Valid',
          invalid: 'Invalid',
          risky: 'Risky',
          alert: {
            learnMoreBtn: 'Learn More',
            riskyCopy: {
              primaryText:
                "The existence of this email address cannot be verified. To confirm its validity, contact the recipient individually. Bulk sending to risky email addresses can damage your sender's reputation and make it more difficult to deliver your emails in the future. If a large number of your emails bounce, this can also occur. It is best to send emails to risky addresses individually.",
              secondaryText:
                'Email addresses are validated when receiving an email response from them. If you spoke with the contact and confirmed the email, ask them to respond to your last email.'
            },
            validationDateLabel: 'Validation Date: '
          },
          table: {
            tooltip: {
              invalid: "This email is invalid and can't be mailed",
              risky: 'This email is risky and mail it could affect deliverability'
            }
          }
        },
        referenceRelease: 'Reference Release',
        linkEntitiesTitle: `{entity} Assigned Successfully`,
        linkEntitiesDescription: `Assigned to {profileName}`,
        summaryLabelDate: 'Added Date',
        summaryLabelCreationBy: 'Added By',
        searchEntities: 'Search for an existing {entity} or',
        newEntity: 'You are now creating a new {entity} but you can',
        selectEntityButton: 'Select an existing {entity}',
        selectEntitiesButton: 'Select an existing {entity} or {additionalEntity}',
        assignRecruiter: 'Assign to Recruiter',
        startMarketing: 'Start Marketing',
        startRecruiting: 'Start Recruiting',
        editionModes: {
          update: 'Updated',
          add: 'Added'
        },
        edition: `{inventoryItem} updated successfully`
      },
      sections: {
        overview: {
          activityButtonLabel: 'See all Activities',
          noteButtonLabel: 'See all Notes'
        },
        tabs: {
          activityNote: {
            creationAction: '{section} {action} Successfully',
            activityActionText: 'New Activity',
            noteActionText: 'New Note',
            deleteAction: {
              title: 'Delete {section}'
            },
            drawer: {
              backAction: 'Back to Contact',
              title: {
                activity: 'Activity',
                note: 'Note'
              },
              alert:
                "Once your activity is added, you won't be able to edit or delete it. Are you sure you want to add this activity?",
              confirmButton: 'Add Activity'
            }
          },
          employees: {
            additionButton: 'Add Employee',
            search: 'Search contact by name or email',
            failedSearch: 'No Contacts found'
          },
          hiringAuthorities: {
            dialogTitle: 'Remove Hiring Authority',
            dialogDescription: 'Are you sure you want to remove {hiringAuthority} from {company}',
            additionButton: 'Add Hiring Authority',
            delete: {
              title: 'Hiring Authority Removed Successfully'
            },
            update: `Hiring Authority {hiringAuthorityEditionMode} Successfully`,
            editTitle: 'Edit Hiring Authority',
            addTitle: 'Add Hiring Authority to this Company'
          },
          changeLogs: {
            sectionName: 'Change Logs',
            drawer: {
              added: 'Added:',
              changes: 'Changes',
              createdBy: 'Change Log By',
              deleted: 'Deleted:',
              previous: 'Previous Content:',
              update: 'Updates',
              empty: 'No changes detected.'
            }
          },
          referenceCheck: {
            sectionName: 'Reference Check',
            modalTitle: 'Telephone Reference Check',
            infoText: 'At least 2 references are required to create a Placement',
            drawer: {
              added: 'Reference Check Added Successfully',
              updated: 'Reference Check Updated Successfully'
            },
            deleted: 'Reference Check Deleted Successfully',
            confirmDelete: 'Are you sure you want to delete this Reference Check?'
          },
          jobOrders: {
            actions: {
              assign: 'Assign Job Order',
              create: 'Create Job Order',
              remove: 'Remove Job Order'
            },
            drawer: {
              placeholder: 'Search a Job order by Position, Functional Title, or Company'
            }
          },
          sendouts: {
            delete: `Sendout can't be deleted `
          },
          placements: {
            action: 'Create Placement'
          }
        },
        candidates: {
          referenceReleaseTitle: 'Send Reference Release Email',
          referenceReleaseMessage: 'Are you sure you want to send this email to {email}?',
          candidateSheetEdit: 'The write up was updated successfully',
          candidateEdit: 'The candidate was updated successfully',
          confirmJobOrderDeletetion: 'Are you sure you want to remove this Job Order from',
          onJobOrderDeleteSuccess: 'Removed from',
          drawerPlaceholder: 'Search a Candidate by Name, Title or Functional Title',
          duplicatedCandidateDialog: {
            title: 'Duplicated Email',
            message: `You're having the same email address for both profiles <b>{email}</b>. We recommend setting up a different one for better bulk sends. Are you sure you want to save this Hiring Authority with the same email address?`
          },
          duplicatedEmailHint: 'We recommend to set a different email address.',
          linkCandidateDialog: {
            title: 'Link Profiles',
            message: `Are you sure you want to link these Candidate and Hiring Authority profiles for <b>{hiringName}?</b>`
          },
          unlinkHiringDialog: {
            title: 'Unlink Profiles',
            message: `Are you sure you want to unlink the Candidate and Hiring Authority profiles for <b>{hiringName}?</b>`
          }
        },
        jobOrders: {
          updateProfile: 'Job Order Updated Successfully',
          notAvailableStrings: {
            drawerTitle: 'New Sendout',
            title: 'Company not signed yet',
            description: 'To create a sendout, this company must be signed',
            buttonTitle: 'Sign Company'
          },
          deleteAlert: {
            severity: 'success',
            title: 'Awesome',
            body: 'The Job Order was removed from this Candidate successfully'
          },
          duplicateDialog: {
            confirmButtonText: 'Duplicate',
            message:
              'Are you sure you want to duplicate this Job Order? You can modify it later if needed.',
            title: 'Duplicate Job Order'
          },
          successDuplication: 'Job Order Created Successfully'
        }
      },
      quickView: {
        initialText: 'Go to Profile'
      }
    },
    recruiterProfile: {
      cashInTitle: 'Career Cash-In',
      cashInYearToDate: 'Cash-In YTD',
      channelPartnerTotal: 'Production',
      channelPartner: 'Channel Partner',
      emptyChannelPartner: 'Join the Channel Partner Program to start earning',
      fallOffsYearToDate: 'Fall Offs YTD',
      monthlyFallOffs: 'Fall Offs',
      placementCardPlural: 'Placements',
      placementCardSingular: 'Placement',
      placementsYearToDate: 'Placements YTD',
      productionYearToDate: 'Production YTD',
      statsCashIn: 'Top 50 YTD Chash-In',
      statsRegion: 'Position by Region',
      statsSendouts: 'Weekly Sendouts Stats',
      statsTeam: `Position by team {team}`,
      statsWeek: 'Position in current Week',
      noteAddition: 'Note Added Successfully',
      feedbackAction: 'Feedback Notes',
      emptyStateNotes: 'No Notes to Show',
      cashInPositon: 'Work hard, seize opportunities, and push your limits to reach the Top 50',
      titleNameTextBox: 'title',
      noteTitleValidation: 'The title is required',
      milestones: {
        searchConsultant: {
          searchConsultant: {
            title: 'Search Consultant'
          },
          searchConsultantTitle: 'Search Consultant',
          initial: 'Work your way trough to become a Sr. Search Consultant',
          successSenior: 'Congrats! You have achieved the Sr. Search Consultant position',
          initialSenior: 'Work your way through become a Director',
          successDirector: 'Congrats! You have achieved the Director Position',
          initialSeniorDirector: 'Work your way through become a Sr. Director',
          successSeniorDirector:
            'Congrats on becoming a Sr. Director! Your hard work and dedication have set you apart'
        },
        alphaPac: {
          progress: 'Embrace the challenge and work hard to unlock the Alpha Pac Trip',
          success: "Pack your bags! You're ready for the next Alpha Pac Trip"
        }
      }
    },
    hiringAuthorities: {
      removeCompanyTitle: 'Remove Company',
      successCompanyRemoved: 'Company Removed Successfully',
      messages: {
        confirmRemoveCompany:
          'Are you sure you want to remove the relationship between {company} and {hiringAuthority}?',
        successRemoveCompany: '{company} removed from {hiringAuthority}',
        confirmMainCompany:
          'Are you sure you want to assign this Company as the main one for this H.A.?',
        successMainCompany: 'The Company was marked as main successfully'
      },
      form: {
        newHiringAuthority: 'You are now creating a new Hiring Authority but you can',
        existingHiringAuthority: 'Search for an existing Hiring Authority or',
        addedCompany: 'Select for an existing H.A. from Company',
        addedContacts: 'Select an existing Contact or Hiring Authority',
        addedToCompany:
          'This Hiring Authority will be added to the Company and to the Job Order at the same time.'
      }
    },
    email: {
      aboutSignature: 'Your official gpac email signature will be added automatically.'
    },
    pil: {
      title: 'Industries',
      secondTitle: 'Industry Details',
      instructions: 'Click on any Industry to view its details',
      description: '{pil} Description',
      emptyDescription: '(without description)',

      functionalTitles: {
        instructions: 'Click on any Specialty to view its details',
        title: 'Functional Titles',
        empty: "This specialty doesn't have functional titles."
      }
    },
    teams: {
      title: 'Teams',
      instructions: 'Select any Specialty to preview the teams working on it.'
    },
    companies: {
      plural: 'Companies',
      creation: {
        title: 'New Company',
        mainSection: 'Contact Data',
        hiringAuthorities: {
          title: 'Hiring Authorities',
          helper:
            "You can add the Hiring Authority later, but remember that it'll be needed to create a Job Order",
          add: 'Add Hiring Authority'
        },
        fields: {
          name: 'Name *',
          legalName: 'Legal Name',
          specialty: 'Specialties *',
          subspecialty: 'Subspecialties',
          phone: {
            label: 'Phone *',
            placeholder: '(999)-999-9999'
          },
          ext: {
            label: 'Ext.',
            placeholder: '999'
          },
          email: 'Email',
          accountsPayableEmail: 'Accounts Payable Email',
          address: 'Address *',
          website: 'Website',
          linkedinUrl: 'LinkedIn URL',
          type: 'Type'
        },
        success: 'Awesome! The Company was created successfully',
        duplicateHiringAuthorities: {
          warning:
            'There are duplicate hiring authorities selected for this company. Remove duplicate information to be able to save'
        },
        similarCompanies: {
          title: 'Similar Companies Found',
          subtitle: "Please make sure you're not adding a duplicate.",
          alert: "We found similar Companies that match the information you're registering."
        },
        targetCompany: {
          label: 'Is it a Target Company?',
          helperText:
            'A Priority Target is a company desired to be client for gpac which means it should be worked with high priority.',
          options: {
            showTargetCompanies: 'Show target companies only',
            excludeTargetCompanies: 'Exclude target companies'
          }
        },
        exactMatch: {
          title: 'Companies Already Registered',
          subtitle: "These are the exact matches of the information you're registering.",
          alert:
            "{name} is already in FortPac and won't be saved to avoid duplicates. If this is an error, please contact Fortpac Support"
        },
        associated: {
          title: 'Associated Companies',
          subheader: 'Establish relationships to have a better Company structure',
          relationship: {
            parent: 'Parent Company',
            sibling: 'Sibling Companies',
            child: 'Child Companies'
          },
          warnings: {
            mixedCompanies: {
              childParent: 'A Child Company is also selected as Parent Company.',
              childSibling: 'A Child Company is also selected as Sibling Company.',
              siblingParent: 'A Sibling Company is also selected as Parent Company.',
              edit: 'Edit the information to be able to save'
            }
          },
          tab: {
            alert: {
              add: 'added',
              remove: 'removed',
              addTitle: 'Success',
              body: '{association} {entity} {actionText} successfully'
            }
          },
          dialog: {
            removeTitle: 'Remove Association',
            removeMessage:
              'This will affect some associations of <b>{companyName}</b> with other companies. <br> Are you sure you want to remove this {associationType} Company?',
            confirmButtonText: 'Remove',
            cancelButtonText: 'Keep'
          },
          form: {
            parent: {
              description: 'Select the Parent Company for',
              placeholder: 'Search by Company Name or Location'
            },
            childSibling: {
              tranferList: {
                companiesSelected: 'Companies Selected',
                results: 'Results'
              },
              disabledReasons: {
                cantBeSelected: "can't be selected because",
                hasAlreadyParent: 'has already a Parent',
                isASister: "it's the current Sibling of this Company",
                isCurrentParent: "it's the current Parent of this Company"
              }
            }
          },
          overview: {
            name: 'Name',
            industry: 'Industry: Specialty',
            location: 'Country: State',
            ha: 'Hiring Authority'
          }
        }
      },
      typeHelper:
        'If your company is already a vendor or client, upload your agreement and ask Ops to mark it accordingly'
    },
    inventory: {
      assignment: {
        title: 'Assign to a recruiter',
        helper: 'Choose a recruiter to assign this item to',
        placeholder: 'Recruiters in your team'
      },
      phoneNumbers: {
        component: {
          title: 'Contact Phone Numbers',
          defaultRadio: 'Set as a Default Phone',
          removeTooltip: 'Remove Phone',
          addNewPhoneBtn: 'Add New Phone'
        }
      },
      jobOrders: {
        create: {
          writeUpDataSheet: {
            title: 'Write Up Assignment Sheet',
            subTitle:
              'It is required to fill the Write Up Assignment Sheet. The Assignment Sheet can be completed once the Job Order is created.',
            actions: {
              fillIn: 'Fill In'
            }
          }
        },
        editDataSheet: {
          contentPageTitle: 'Edit Assignment Sheet',
          title: 'Assignment Sheet',
          subheader: 'Fields marked with * are required',
          uiMessages: {
            warning: 'Please fill in the required fields',
            error: 'Some fields have inconsistencies or are missing information',
            skillsError:
              'Please enter a valid value. Avoid using special characters; use commas to divide skills',
            complete: {
              success: 'Assignment Sheet Saved Successfully',
              error: 'There was an error saving the Assignment Sheet'
            },
            draft: {
              success: 'Assignment Sheet Progress Saved Successfully',
              error: 'There was an error saving the Assignment Sheet Progress'
            }
          }
        },
        profile: {
          dataSheetDescription:
            'This information is from the Write Up Assignment Sheet. To create a Placement, the Assignment Sheet must be filled.\nThe completed Assignment Sheet will be used for future job posting and bulking integration.',
          editButton: 'Edit Assignment Sheet',
          cards: {
            feeAgreement: {
              title: 'Fee Agreement Details'
            },
            position: {
              title: 'Position Details'
            },
            availableDateInterviews: {
              title: 'Available Interview Dates '
            },
            notes: {
              title: 'Additional Details'
            },
            dataSheet: {
              title: 'Details'
            },
            generalData: {
              title: 'General Data'
            },
            companyData: {
              title: 'Company Data'
            }
          }
        }
      },
      urgencyVerification: {
        fields: {
          verified: 'Urgency reviewed and verified. *',
          actions: 'Actions Taken *',
          details: 'Details'
        },
        title: 'Verify Urgency',
        subtitle: 'Urgency Verification',
        description:
          'This will validate the urgency state of the item and it will be shown as Hot Inventory',
        results: {
          candidate: 'This Candidate has been verified',
          jobOrder: 'This Job Order has been verified'
        }
      }
    },
    dashboard: {
      shared: {
        gpac: 'gpac',
        regional: 'regional',
        scope1: 'gpac All',
        scope2: 'All Coaches',
        region: 'Region',
        team: 'Team'
      },
      emptyMessage: 'There is no data with the selected filters',
      toolsUsage: {
        title: 'Tools Usage',
        updateInfo: {
          thirtyMinutesUpdate:
            '{updatedFields} update every 30 minutes. Last updated on {date} at <b>{time} (CST)</b>',
          inMails:
            'LinkedIn InMails data has a 24-hour delay, updating daily at 1:30 PM. LinkedIn may take up to 48 hours to sync all accounts. Last updated on {date} at <b>{time} (CST)</b>',
          livePosts:
            'Live Posts have a 24 hour delay, updating every day at 7:30 AM. Last updated on {date} at <b>{time} (CST)</b>'
        }
      },
      toolsUsageDashboard: {
        title: 'Tools Dashboard'
      }
    },
    fileManager: {
      deleteFile: {
        title: 'Delete File',
        message: 'Are you sure you want to delete "{fileName}"?',
        successTitle: 'File Deleted Successfully',
        successMessage: '"{fileName}" was deleted successfully'
      },
      dropzone: {
        disabled: 'This section already has {maxFiles} files uploaded',
        enabled: 'Drop your files here or click to browse (Max. {maxFileSize} per file)',
        uploading: 'Uploading file... (Size: {fileSize})'
      },
      duplicateFile: {
        title: 'Existing File',
        message: '"{fileName}" is already uploaded'
      },
      extFile: {
        title: 'Invalid File Extension',
        message:
          'The following extensions are allowed: jpg, jpeg, png, pdf, doc, docx, ppt, pptx, xlsx, xls, csv'
      },
      fileItem: {
        menuItemsTitles: {
          delete: 'Delete',
          download: 'Download',
          view: 'View',
          moveTo: 'Move to'
        }
      },
      fileName: {
        title: 'Invalid File Name',
        message: `Ensure the file name doesn't have special characters such as /\\ ^ <> ~ "[ ] : + = ÷ ; : , ' ? * @ | $ # %`
      },
      moveFileConfirm: {
        title: 'Move to',
        message: 'File "{fileName}"'
      },
      newProfile: {
        sectionTitle: 'Attachments',
        sectionSubtitle:
          'You can upload files later but we suggest you upload the {categorySuggested} file at least'
      },
      sizeFile: {
        title: 'File Size Limit Exceeded',
        message:
          "{fileName} is larger than {maxFileSize} and can't be added, please select a smaller file"
      },
      validQuantity: {
        title: 'File Limit Reached',
        message: 'This section has already reached the file limit',
        body: 'You can only upload {maxFiles} files in this section'
      },
      error: {
        title: 'Attachments Error'
      }
    },
    leaderboard: {
      noDailyLeaders: {
        title: 'No leaders yet',
        subtitle: 'Hurry! Send three or more Sendouts today to stay on top of the leaderboard.'
      },
      noWeeklyLeaders: {
        title: 'No Leaders yet',
        subtitle:
          'Go further and send five or more Sendouts on this week to climb up on the leaderboard!'
      }
    },
    notifications: {
      denied: `Notifications blocked. You may be missing important alerts. Allow FortPac notifications in your browser settings. Please contact Fortpac Support.`,
      granted: `Notifications are currently off. You may miss important alerts. To receive notifications,`,
      error: {
        title: 'Notifications'
      }
    },
    collections: {
      title: 'AR & Collections',
      columns: {
        id: 'Internal ID',
        invoiceNo: 'Invoice #',
        placementId: 'Placement ID',
        phone: 'HQ Phone',
        commitToPay: 'Comm to Pay',
        commitDate: 'Comm Date',
        commitAmount: 'Amount',
        memo: 'Memo',
        dueDate: 'Due Date',
        total: 'Total',
        remaining: 'Remaining',
        company: 'Company',
        ha: 'Hiring Authority',
        candidate: 'Candidate',
        recruiter: 'Recruiter',
        coach: 'Team on Invoice',
        currentCoach: 'Team',
        position: 'Position',
        notes: 'Notes',
        assignedForCollections: 'Assigned For Collections'
      },
      fields: {
        committedToPay: 'Committed to Pay?',
        commitDate: 'Commit Date',
        commitAmount: 'Commit Amount',
        notes: 'Collection Notes'
      },
      ctas: {
        view: 'View Placement'
      },
      messages: {
        recordUpdated: 'Invoice updated successfully',
        recordNotUpdated: 'Invoice not updated as expected. Please contact Fortpac Support'
      },
      filters: {
        terms: 'Show Invoices of Termed and Former Regional Directors',
        searchInSplit: 'Search also in Candidate side of the split',
        status: 'Status',
        next2weeks: 'Next 2 Weeks',
        lastMonthsAndTwoWeeks: 'Last 12 Months + 2 Weeks',
        pastDueDate: 'days past due date'
      }
    },
    commandCenter: {
      title: 'Command Center',
      healthDistributionDrawers: {
        behind: {
          title: 'Falling Behind Recruiters'
        },
        atRisk: {
          title: 'At Risk Recruiters'
        },
        headcount: {
          title: 'All recruiters'
        },
        inTraining: {
          title: 'In Training Recruiters'
        },
        onTrack: {
          title: 'On Track Recruiters'
        },
        under3Months: {
          title: 'Under 3 Months Recruiters'
        }
      },
      drawers: {
        fallOffs: {
          title: 'Fall Offs'
        },
        placements: {
          title: 'Placements'
        },
        sendouts: {
          title: 'Sendouts'
        },
        sendovers: {
          title: 'Sendovers'
        },
        sentAgreements: {
          title: 'Sent Agreements'
        },
        signedAgreements: {
          title: 'Signed Agreements'
        }
      },
      healthDistributionInfo: {
        atRisk: {
          label: 'At Risk',
          value:
            'Draw balance is higher than 1.5 Months and Outstanding Production + Cash in is lower than Draw Balance'
        },
        behind: {
          label: 'Behind',
          value:
            'Draw balance is lower than 1.5 Months and Outstanding Production + Cash in is lower than Draw Balance'
        },
        onTrack: {
          label: 'On Track',
          value: 'Outstanding Production + Cash In is higher than Draw Balance'
        }
      },
      widget: {
        errorTemplate: {
          action: 'Try again',
          subtitle: 'Please try again. If the problem persists, contact FortPac Support.',
          title: `Couldn't Retrieve Information`
        }
      },
      financeHealthLabels: {
        monthProduction: 'Month Production',
        monthCashIn: 'Month Cash In',
        ytdProduction: 'YTD Production',
        ytdCashIn: 'YTD Cash In',
        pdaYtd: 'PDA YTD',
        adjustedAverage: 'Adjusted %',
        trackingProduction: 'Tracking Production'
      },
      metricsTitles: {
        adjustedAverage: 'Adjusted Avg',
        avgFee: 'Avg. Fee',
        bulks: 'Bulks',
        calls: 'Calls',
        cashIn: 'Cash In',
        drawBalance: 'Draw Balance',
        estimate: 'Estimate',
        feeAgreementsSent: 'Sent Agreements',
        feeAgreementsSigned: 'Signed Agreements',
        fallOffRatePercent: 'Fall Off Rate',
        inMails: 'InMails',
        outstandingBillings: 'Outstanding Billing',
        peopleReached: 'People Reached',
        perDeskAverage: 'PDA',
        phoneTime: 'Phone Time (Hrs)',
        placedRatio: 'SO/Placement',
        posts: 'Posts',
        production: 'Production',
        sendouts: 'Sendouts',
        sendovers: 'Sendovers',
        totalFallOffs: 'Fall Offs',
        totalPlacements: 'Placements',
        trackingProduction: 'Tracking Prod.'
      },
      dominateSheet: {
        title: 'Dominate Sheet',
        mainTabs: {
          followUpTasks: 'Follow Up Tasks',
          hotInventory: 'Hot Inventory',
          interviews: 'Interviews',
          outstandingBillings: 'Outstanding Billings'
        },
        closingCalls: {
          title: 'Closing Calls'
        },
        debriefingCalls: {
          title: 'Debriefing Calls'
        },
        hotCandidates: {
          headerAction: 'Show pending to be verified',
          title: 'Hot Candidates'
        },
        hotInventoryStatsSummary: {
          subtitle: 'Only verified items are considered',
          title: 'Hot Inventory Estimated Commissions'
        },
        hotJobOrders: {
          headerAction: 'Show pending to be verified',
          title: 'Hot Job Orders'
        },
        preparationCalls: {
          title: 'Preparation Calls'
        },
        unconvertedSendovers: {
          title: 'Unconverted Sendovers'
        },
        unsignedAgreements: {
          title: 'Unsigned Agreements'
        }
      },
      activityPage: {
        title: 'Activity',
        mainTabs: {
          overview: 'Overview',
          performanceReports: 'Performance Reports'
        },
        notFound: {
          subtitle:
            "This team or recruiter can't be found. Please verify the URL is correct. If the problem persists, contact FortPac Support.",
          title: '404 - Page Not Found'
        }
      },
      teamPage: {
        path: 'Overview',
        title: 'Team'
      },
      finance: {
        financialProductivityStatsSummary: {
          title: 'Financial YTD Overview'
        },
        healthDistribution: {
          subtitle:
            'Healthy Index is based on the difference between Draw Balance and Outstanding Production + Cash In',
          title: 'Financial Health Distribution (YTD)'
        },
        lowHealthMembersByTeam: {
          subtitle: '',
          title: 'Low Health Members by Team'
        },
        teamMembersHealth: {
          title: 'Team Members by Health Status'
        },
        productionCashInByMonth: {
          subtitle:
            'Healthy Index is based on the difference between Draw Balance and Outstanding Production + Cash In',
          title: 'Production and Cash in by Month'
        },
        PDAByTenure: {
          title: 'Per Desk Average YTD by Tenure'
        }
      },
      activity: {
        assignedInventory: {
          subtitle: `Amount of Inventory in the recruiter's PIL`,
          title: 'Assigned Inventory'
        },
        callsGauge: {
          title: 'Calls',
          chart: {
            goal: 'Calls Goal',
            members: 'Members on Goal',
            footerLabel: 'Goal is 80 daily or 2 hrs phone time'
          }
        },
        callsTrends: {
          title: 'Calls'
        },
        financialStatsSummary: {
          title: 'Financial YTD'
        },
        inMailsGauge: {
          title: 'InMails',
          chart: {
            goal: 'InMails Goal',
            members: 'Members on Goal',
            footerLabel:
              'Goal progress averages 20 InMails/day. (LinkedIn has a 30-hr. report delay. Last updated at 1:30 PM)'
          }
        },
        goalsProgressInTime: {
          title: 'Goals Progress Through Time'
        },
        activityTrends: {
          title: 'Hourly Call Trends'
        },
        inMailsTrends: {
          title: 'InMails'
        },
        peopleReachedGauge: {
          title: 'People Reached',
          chart: {
            goal: 'Ppl Reached Goal',
            members: 'Members on Goal',
            footerLabel: 'Goal is 100 daily'
          }
        },
        interviewsScheduledThisWeek: {
          title: 'Interviews Scheduled This Week'
        },
        peopleReachedTrends: {
          title: 'People Reached'
        },
        phoneTimeGauge: {
          title: 'Phone Time (Hours)',
          chart: {
            goal: 'Time (Hrs) Goal',
            members: 'Members on Goal',
            footerLabel: 'Goal is 80 daily or 2 hrs phone time'
          }
        },
        phoneTimeTrends: {
          title: 'Phone Time (Minutes)'
        },
        pil: {
          title: 'PIL',
          industries: 'Industries'
        },
        placementsStatsSummary: {
          title: 'Placements YTD'
        },
        productivityStatsSummary: {
          title: 'Productivity YTD'
        },
        productionTrends: {
          title: 'Production'
        },
        placementsWithTodayStartDate: {
          title: "Placements with Today's Start Date"
        },
        weeklyActivityStatsSummary: {
          title: `Current Week Activity`
        },
        weeklyInventoryGrowthStatsSummary: {
          title: `Current Week Inventory Growth`
        },
        overallPerformance: {
          title: 'Overall Performance',
          footer: {
            overallPerformanceFooter: {
              metrics: {
                calls: {
                  title: 'Calls',
                  goals: 'Daily 60, Weekly 300'
                },
                phoneTime: {
                  title: 'Phone Time',
                  goals: 'Daily 2 hrs, Weekly 10 hrs'
                },
                bulks: {
                  title: 'Bulks',
                  goals: 'Daily 1, Weekly 5'
                },
                peopleReached: {
                  title: 'People Reached',
                  goals: 'Daily 100, Weekly 500'
                },
                inMails: {
                  title: 'InMails',
                  goals: 'Daily 20, Weekly 100'
                },
                posts: {
                  title: 'Posts',
                  goals: '100 live at all times with 5 new postings daily'
                }
              }
            },
            infoLabel: '* Since tracked'
          }
        },
        weeklyProductivityStatsSummary: {
          title: `Current Week Productivity`
        }
      },
      teamGrowth: {
        productionStatsSummary: {
          title: 'Production Overview'
        },
        yearlyFinancialStatsSummary: {
          title: 'Financial Overview (YTD)'
        },
        yearlyHumanResourcesStatsSummary: {
          title: 'Retention and Turnover (YTD)'
        },
        yearlyPlacementsStatsSummary: {
          title: 'Placements Overview (YTD)'
        },
        yearlyProductivityStatsSummary: {
          title: 'Productivity Overview (YTD)'
        }
      },
      overview: {
        title: 'Overview'
      }
    },
    messageCenter: {
      title: 'Message Center',
      emptyState: {
        title: 'No Messages to Show',
        subtitle: 'Once you send a text message, it will be shown here.',
        errorTitle: 'An error has ocurred. Please reload.',
        errorSubtitle: 'If the problem persists, contact FortPac Support.'
      },
      messageComposer: {
        placeholder: 'Write a message (Shift + Enter for new line)',
        tooltip: 'Send Message'
      }
    }
  }
});

export default strings;
