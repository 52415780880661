// @flow
import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  ActivityTrends,
  AssignedInventoryStatsSummary,
  CallsTrends,
  Drawers,
  FinancialStatsSummary,
  GoalsProgressInTime,
  InMailsTrends,
  LayoutSkeleton,
  PeopleReachedTrends,
  PhoneTimeTrends,
  PilMap,
  PlacementsTrends,
  ProductivityStatsSummary,
  RecruiterLayout,
  SendoutsTrends,
  WeeklyActivityStatsSummary,
  WeeklyInventoryGrowthStatsSummary,
  WeeklyProductivityStatsSummary
} from 'features/command-center';
import { DrawerManagerProvider } from 'features/command-center/contexts';
import { useRefreshStats } from 'features/command-center/hooks';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { UIStatus } from 'UI/constants/status';

export const RecruiterOverview = ({ id, match }) => {
  const [recruiterName, setRecruiterName] = useState('');
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const { refreshData, refreshKey } = useRefreshStats();

  const canDoDrillDown = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterDrillDown);
  const recruiterId = id ?? match?.params?.id;
  const requestParams = {
    recruiter: recruiterId.toUpperCase(),
    ...(canDoDrillDown && { version: 1 })
  };

  const { state: weeksCatalogState } = useFetchWithStatusV2({
    endpoint: Endpoints.ToolsUsageReportWeeks
  });

  const weeks = weeksCatalogState?.results;

  const handleRecruiterLoaded = useCallback(recruiterData => {
    setRecruiterName(recruiterData?.recruiterName ?? '');
  }, []);

  return (
    <DrawerManagerProvider>
      <RecruiterLayout
        recruiterId={recruiterId}
        onRefresh={refreshData}
        onRecruiterLoaded={handleRecruiterLoaded}
      >
        {weeksCatalogState.status === UIStatus.Loading ? (
          <LayoutSkeleton />
        ) : (
          <Grid key={refreshKey} container spacing={4}>
            <Grid item xs={12} md={8}>
              <PilMap requestParams={requestParams} />
            </Grid>

            <Grid item xs={12} md={4}>
              <AssignedInventoryStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <FinancialStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <ProductivityStatsSummary
                requestParams={requestParams}
                hasDrillDown={canDoDrillDown}
              />
            </Grid>

            <Grid item xs={12}>
              <GoalsProgressInTime requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <PlacementsTrends requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <SendoutsTrends requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <WeeklyActivityStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={6}>
              <WeeklyProductivityStatsSummary
                requestParams={requestParams}
                hasDrillDown={canDoDrillDown}
              />
            </Grid>

            <Grid item xs={6}>
              <WeeklyInventoryGrowthStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <PeopleReachedTrends weeks={weeks} requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <InMailsTrends weeks={weeks} requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <CallsTrends weeks={weeks} requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <PhoneTimeTrends weeks={weeks} requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <ActivityTrends requestParams={{ ...requestParams }} />
            </Grid>
          </Grid>
        )}
      </RecruiterLayout>
      <Drawers prefixTitle={recruiterName ?? ''} />
    </DrawerManagerProvider>
  );
};
