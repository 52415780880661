// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { confirm, showAlert } from 'actions/app';
import clsx from 'clsx';
import { useGlobalStyles } from 'GlobalStyles';
import useDatatable, {
  buildButtonRendererDefinition,
  buildRowActionsRendererDefinition,
  extractObjectFromDataTable,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import isEmpty from 'lodash/isEmpty';
import { sendReminder } from 'services/FeeAgreement';
import strings from 'strings';
import type { HomeItem } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import DataTable from 'UI/components/organisms/DataTable';
import FeeAgreementDetails from 'UI/components/organisms/feeagreements/FeeAgreementDetails';
import PlacementEditForm from 'UI/components/organisms/placements/PlacementEditForm';
import SendoutManager from 'UI/components/organisms/sendouts';
import { useDataTableStyles } from 'UI/globalStyles/DataTableStyles';
import { SvgAdd } from 'UI/res';

import HomeDialogs from './HomeDialogs';
import { useStyles } from './styles';
import { separateUrl } from './utils';

type HomeTableTemplateProps = {
  areNewMembers: boolean,
  customEmptyState: Object,
  emptyState: Object,
  isTableEmpty: () => void,
  item: HomeItem,
  shouldShowFooterActions: () => void,
  showMore: boolean
};

const DEFAULT_LIMIT_PARAM = 5;

const HomeTableTemplate = ({
  areNewMembers,
  customEmptyState,
  emptyState,
  tableColumns,
  isTableEmpty,
  item,
  shouldShowFooterActions,
  showMore,
  url
}: HomeTableTemplateProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const dataTableClasses = useDataTableStyles();
  const globalClasses = useGlobalStyles();

  const handleActionClick = action => history.push(action);

  const renderAction = (actionLabel, redirectTo, actionIcon = {}) => {
    const { icon = null, iconProps = null } = actionIcon;
    let finalIcon;
    switch (icon) {
      case 'Add':
        finalIcon = SvgAdd;
        break;
      default:
        finalIcon = SvgAdd;
        break;
    }

    return (
      <FPActionButton
        className={classes.emptyStateButton}
        text={actionLabel}
        onClick={() => handleActionClick(redirectTo)}
        icon={icon ? finalIcon : null}
        iconProps={iconProps}
      />
    );
  };

  const tableOptions = {
    sort: false,
    pagination: false,
    responsive: 'standard',
    selectableRowsHeader: false,
    selectableRows: 'none',
    elevation: 0,
    title: emptyState && emptyState.title,
    subtitle: emptyState && emptyState.subtitle,
    children:
      emptyState?.hasAction &&
      renderAction(emptyState.actionLabel, emptyState.redirectTo, emptyState?.actionIcon),
    customEmptyState
  };

  const pageKey = `home-${item.name}-list`;

  const { baseUrl = '', queryParams = '' } = separateUrl(url || item.url) || {};
  const params = new URLSearchParams(queryParams);
  const limit = !showMore ? params.get('limit') || DEFAULT_LIMIT_PARAM : item?.maxLimit;
  const orderByOptions = {
    column: params.get('orderBy') || 'id',
    direction: params.get('direction') || 'asc'
  };

  const sendReminderFA = async ({ rowData }) => {
    const row = extractObjectFromDataTable(
      tableColumns || item.tableColumns,
      ['id', 'last_activity'],
      rowData
    );
    dispatch(
      confirm({
        severity: 'warning',
        showSeverityIcon: false,
        title: strings.feeAgreements.details.titleConfirmSendReminder,
        message: '',
        confirmButtonText: strings.feeAgreements.details.sendReminder,
        cancelButtonText: strings.shared.ui.cancel,
        content: <HomeDialogs.SendReminderConfirmationDialog lastActivity={row?.last_activity} />,
        onConfirm: async ok => {
          if (!ok) return;
          const result = await sendReminder(row.id);
          await getData();
          result.alert && result.alert.body && dispatch(showAlert(result.alert));
        }
      })
    );
  };

  const handleViewDetails = ({ rowData }) => {
    const { id } = extractObjectFromDataTable(tableColumns || item.tableColumns, ['id'], rowData);

    if (item?.tableAction?.redirect) history.push(item.tableAction.uri.replace(':id', id));

    if (item?.tableAction?.openDrawer)
      showOrHideDrawer(item.tableAction.drawerName, true, { selectedRowId: id });
  };

  const Actions = {
    viewDetails: handleViewDetails,
    sendReminder: sendReminderFA
  };

  const getRowActions = rowActions => {
    if (!rowActions) return [];

    const result = rowActions.map(rowAction => ({
      onClick: Actions[rowAction.action] || null,
      ...rowAction
    }));

    return result;
  };

  const getAction = actionColumn => {
    if (!actionColumn) return null;

    let action;
    if (actionColumn?.action === 'sendReminder') action = sendReminderFA;
    return buildButtonRendererDefinition(
      null,
      actionColumn?.text,
      action,
      null,
      actionColumn?.color
    );
  };

  const actionColumn = tableColumns && tableColumns.find(each => each.action);
  const actionButton = getAction(actionColumn);
  const finalActions = item.rowActions && getRowActions(item.rowActions);

  const rowActions =
    finalActions &&
    buildRowActionsRendererDefinition({
      actions: finalActions,
      customName: item?.rowActionsCustomName || 'id'
    });

  const initialPreferences = getColumnPreferences(pageKey, 0, orderByOptions, tableColumns || []);

  const extendParams = useCallback(() => {
    return {
      limit,
      coachId: item?.coachId,
      regionalId: item?.regionalId,
      isForNewRecruiters: Number(areNewMembers)
    };
  }, [areNewMembers, item.coachId, item.regionalId, limit]);

  const { columnPreferences, data, count, getData, listState } = useDatatable({
    key: pageKey,
    endpoint: baseUrl,
    entityName: `Home-${item.name}`,
    initialPreferences,
    columnsDefinitions: tableColumns || item.tableColumns || [],
    paramsExtender: extendParams,
    initialUiState: {
      perPage: 5
    },
    virtualProps: item?.virtualProps || null
  });
  const { isLoading } = listState;
  const { columns } = columnPreferences;
  const finalColumns = getColumnsToRender([...columns, actionButton, rowActions]);
  const shouldHighlightFirstRow = item.shouldHighlightFirstRow && !isEmpty(data);

  useEffect(() => {
    isTableEmpty(isEmpty(data));
  }, [data, isTableEmpty]);

  useEffect(() => {
    count && shouldShowFooterActions(count);
  }, [count, shouldShowFooterActions]);

  const defaultUiState = {
    selectedRowId: 0,
    isFeeAgreementSummaryOpen: false,
    isSendoutDetailsOpen: false,
    isPlacementOpen: false,
    additionalData: null
  };
  const [uiState, setUiState] = useState(defaultUiState);
  const toggleDrawer = (drawer: string, open: boolean) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

    showOrHideDrawer(drawer, open);
  };

  const showOrHideDrawer = (drawer: string, open: boolean, additionalUi: any = {}) => {
    setUiState(prevState => ({ ...prevState, [drawer]: open, ...additionalUi }));
    !open && setUiState(prevState => ({ ...prevState, additionalData: null }));
  };

  return (
    <div>
      <DataTable
        refreshing={isLoading}
        {...tableOptions}
        className={clsx(
          globalClasses.tableToolbarCard,
          dataTableClasses.withoutToolbar,
          shouldHighlightFirstRow && classes.firstRowHighlight
        )}
        data={data}
        columns={finalColumns}
        filter={false}
        selectableRows="none"
      />

      <Drawer
        open={uiState.isFeeAgreementSummaryOpen && !!uiState.selectedRowId}
        onClose={toggleDrawer('isFeeAgreementSummaryOpen', false)}
      >
        <div role="presentation">
          <FeeAgreementDetails
            onEdit={getData}
            onDelete={getData}
            onUpdate={getData}
            onClose={toggleDrawer('isFeeAgreementSummaryOpen', false)}
            feeAgreementId={uiState.selectedRowId}
          />
        </div>
      </Drawer>

      <Drawer
        open={!!uiState.isSendoutDetailsOpen && !!uiState.selectedRowId}
        onClose={toggleDrawer('isSendoutDetailsOpen', false)}
      >
        <div role="presentation">
          <SendoutManager
            onEdit={getData}
            onDelete={getData}
            onUpdate={getData}
            onClose={toggleDrawer('isSendoutDetailsOpen', false)}
            id={uiState.selectedRowId}
          />
        </div>
      </Drawer>

      <Drawer
        open={!!uiState.isPlacementOpen && !!uiState.selectedRowId}
        onClose={toggleDrawer('isPlacementOpen', false)}
      >
        <div role="presentation">
          <PlacementEditForm
            onEdit={getData}
            onDelete={getData}
            onUpdate={getData}
            onClose={toggleDrawer('isPlacementOpen', false)}
            placementId={uiState.selectedRowId}
          />
        </div>
      </Drawer>
    </div>
  );
};

HomeTableTemplate.defaultProps = {
  item: {}
};

export default HomeTableTemplate;
