import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TextBox from 'UI/components/atoms/TextBox';

export const MessageComposer = ({ disabled, onClickSend }) => {
  const [message, setMessage] = useState('');

  const handleChangeText = (name, text) => {
    setMessage(text);
  };

  const handleClick = () => {
    if (!message.trim()) return;
    onClickSend(message.trim());
    setMessage('');
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return;
      }
      e.preventDefault();
      handleClick();
    }
  };

  return (
    <Grid container wrap="nowrap" spacing={2} alignItems="center">
      <Grid item xs={11}>
        <TextBox
          disabled={disabled}
          fullWidth
          multiline
          onChange={handleChangeText}
          onKeyDown={handleKeyDown}
          placeholder={strings.messageCenter.messageComposer.placeholder}
          rows={1}
          rowsMax={2}
          value={message}
        />
      </Grid>

      <Grid item xs={1}>
        <FPIconButton
          onClick={handleClick}
          tooltipProps={{
            title: strings.messageCenter.messageComposer.tooltip
          }}
        >
          <SendIcon color="primary" />
        </FPIconButton>
      </Grid>
    </Grid>
  );
};
