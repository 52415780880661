export const CHAT_FAKE_RESPONSE = [
  {
    id: 1,
    sender: {
      id: 101,
      name: 'Alice'
    },
    message: 'Hey, how are you?',
    timestamp: '2024-09-01T10:01:00Z',
    status: 'delivered',
    direction: 'outbound'
  },
  {
    id: 2,
    sender: {
      id: 201,
      name: 'Bob'
    },
    message: "I'm good! How about you?",
    timestamp: '2024-09-01T10:02:00Z',
    status: 'delivered',
    direction: 'inbound'
  },
  {
    id: 3,
    sender: {
      id: 101,
      name: 'Alice'
    },
    message: "I'm doing well, thanks! What are you up to today?",
    timestamp: '2024-09-01T10:03:00Z',
    status: 'delivered',
    direction: 'outbound'
  },
  {
    id: 4,
    sender: {
      id: 201,
      name: 'Bob'
    },
    message: 'Not much, just relaxing. How about you?',
    timestamp: '2024-09-02T11:00:00Z',
    status: 'delivered',
    direction: 'inbound'
  },
  {
    id: 5,
    sender: {
      id: 101,
      name: 'Alice'
    },
    message: 'Same here, taking it easy!',
    timestamp: '2024-09-02T11:05:00Z',
    status: 'delivered',
    direction: 'outbound'
  },
  {
    id: 6,
    sender: {
      id: 101,
      name: 'Alice'
    },
    message: 'Did you see the game last night?',
    timestamp: '2024-09-03T09:30:00Z',
    status: 'delivered',
    direction: 'outbound'
  },
  {
    id: 7,
    sender: {
      id: 201,
      name: 'Bob'
    },
    message: "Yes! It was amazing, can't believe the final score.",
    timestamp: '2024-09-03T09:35:00Z',
    status: 'delivered',
    direction: 'inbound'
  },
  {
    id: 8,
    sender: {
      id: 201,
      name: 'Bob'
    },
    message: 'Hey, any plans for the weekend?',
    timestamp: '2024-09-04T08:15:00Z',
    status: 'delivered',
    direction: 'inbound'
  },
  {
    id: 9,
    sender: {
      id: 101,
      name: 'Alice'
    },
    message: "Not yet, maybe I'll go hiking. You?",
    timestamp: '2024-09-04T08:18:00Z',
    status: 'delivered',
    direction: 'outbound'
  },
  {
    id: 10,
    sender: {
      id: 201,
      name: 'Bob'
    },
    message: 'Hey, how are you again?',
    timestamp: '2024-10-01T08:18:00Z',
    status: 'delivered',
    direction: 'inbound'
  }
];

export const FORMATTED_CHAT = {
  '2024-09-01': [
    {
      id: 1,
      sender: {
        id: 101,
        name: 'Alice'
      },
      message: 'Hey, how are you?',
      timestamp: '2024-09-01T10:01:00Z',
      status: 'delivered',
      direction: 'outbound'
    },
    {
      id: 2,
      sender: {
        id: 201,
        name: 'Bob'
      },
      message: "I'm good! How about you?",
      timestamp: '2024-09-01T10:02:00Z',
      status: 'delivered',
      direction: 'inbound'
    },
    {
      id: 3,
      sender: {
        id: 101,
        name: 'Alice'
      },
      message: "I'm doing well, thanks! What are you up to today?",
      timestamp: '2024-09-01T10:03:00Z',
      status: 'delivered',
      direction: 'outbound'
    }
  ],
  '2024-09-02': [
    {
      id: 4,
      sender: {
        id: 201,
        name: 'Bob'
      },
      message: 'Not much, just relaxing. How about you?',
      timestamp: '2024-09-02T11:00:00Z',
      status: 'delivered',
      direction: 'inbound'
    },
    {
      id: 5,
      sender: {
        id: 101,
        name: 'Alice'
      },
      message: 'Same here, taking it easy!',
      timestamp: '2024-09-02T11:05:00Z',
      status: 'delivered',
      direction: 'outbound'
    }
  ],
  '2024-09-03': [
    {
      id: 6,
      sender: {
        id: 101,
        name: 'Alice'
      },
      message: 'Did you see the game last night?',
      timestamp: '2024-09-03T09:30:00Z',
      status: 'delivered',
      direction: 'outbound'
    },
    {
      id: 7,
      sender: {
        id: 201,
        name: 'Bob'
      },
      message: "Yes! It was amazing, can't believe the final score.",
      timestamp: '2024-09-03T09:35:00Z',
      status: 'delivered',
      direction: 'inbound'
    }
  ],
  '2024-09-04': [
    {
      id: 8,
      sender: {
        id: 201,
        name: 'Bob'
      },
      message: 'Hey, any plans for the weekend?',
      timestamp: '2024-09-04T08:15:00Z',
      status: 'delivered',
      direction: 'inbound'
    },
    {
      id: 9,
      sender: {
        id: 101,
        name: 'Alice'
      },
      message: "Not yet, maybe I'll go hiking. You?",
      timestamp: '2024-09-04T08:18:00Z',
      status: 'delivered',
      direction: 'outbound'
    }
  ],
  '2024-10-01': [
    {
      id: 10,
      sender: {
        id: 201,
        name: 'Bob'
      },
      message: 'Hey, how are you again?',
      timestamp: '2024-10-01T08:18:00Z',
      status: 'delivered',
      direction: 'inbound'
    }
  ]
};
